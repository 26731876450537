import ReactDOM from 'react-dom/client';
import './styles/styles.css';
import App from './App';

import './lib/i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
