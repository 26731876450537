/*
Text drawing effect is ported from Generative Design book http://www.generative-gestaltung.de 
Original license: http://www.apache.org/licenses/LICENSE-2.0
*/

import { useEffect, useRef, useState } from 'react';
import useTheme from '../../theme/useTheme';
import { Vector2 } from '../../types';
import { getDistance } from '../../utils/math';

const breakChars = [' ', '.', '?', '!', ',', ';', ':', '\n'];
let word = '';
const wordPosition: Vector2[] = [];

type ComposeCanvasProps = {
  quote: string;
  onComposingComplete: (stepSizes: number[]) => void;
  onDrawingPaused: () => void;
  onDrawingStart: () => void;
  containerRef: React.RefObject<HTMLDivElement>;
  onWordDraw: (options: {
    speed: number;
    x: number;
    y: number;
    prevX: number;
    prevY: number;
    seconds: number;
    word: string;
    wentDown: boolean;
    wentRight: boolean;
    isFirst?: boolean;
    isLast?: boolean;
  }) => void;
};

const QuoteDrawer = ({
  quote,
  onComposingComplete,
  onDrawingPaused,
  onDrawingStart,
  containerRef,
  onWordDraw,
}: ComposeCanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [composingComplete, setComposingComplete] = useState<boolean>(false);

  const { applyTheme } = useTheme();

  const fontName = 'Alfred Serif';
  let charPosition: Vector2;
  let prevX: number;
  let prevY: number;
  let charIndex = 0;
  const minFontSize = 3;
  let pointerDown = false;
  let canvas: HTMLCanvasElement;
  let context: CanvasRenderingContext2D;
  const stepSizes: number[] = [];
  let charDistance: number;
  let drawStartTime: number;

  useEffect(() => {
    if (containerRef.current && canvasRef.current) {
      canvas = canvasRef.current;
      const container = containerRef.current;
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
      charPosition = { x: 0, y: 0 };
      context = canvas.getContext('2d')!;

      if (!context) {
        console.error('Unable to get canvas context');
      }

      setupCanvasEventListeners();

      return () => {
        removeCanvasEventListeners();
      };
    }
  }, []);

  const handleComposingComplete = () => {
    if (!composingComplete && canvasRef.current) {
      setTimeout(() => {
        applyTheme('dark');
        onComposingComplete(stepSizes);
      }, 500);

      removeCanvasEventListeners();
    }
    setComposingComplete(true);
  };

  const setupCanvasEventListeners = () => {
    //console.log('Setting up canvas event listeners');

    canvas.addEventListener('mousedown', handleStartDrawing, false);
    canvas.addEventListener('mousemove', handleDrawing, false);
    canvas.addEventListener('mouseup', handleStopDrawing, false);
    canvas.addEventListener('mouseout', handleStopDrawing, false);

    canvas.addEventListener('touchstart', handleStartDrawing, false);
    canvas.addEventListener('touchmove', handleDrawing, false);
    canvas.addEventListener('touchcancel', handleStopDrawing, false);
    canvas.addEventListener('touchend', handleStopDrawing, false);
  };

  const removeCanvasEventListeners = () => {
    //console.log('Removing canvas event listeners');

    canvas.removeEventListener('mousedown', handleStartDrawing, false);
    canvas.removeEventListener('mousemove', handleDrawing, false);
    canvas.removeEventListener('mouseup', handleStopDrawing, false);
    canvas.removeEventListener('mouseout', handleStopDrawing, false);

    canvas.removeEventListener('touchstart', handleStartDrawing, false);
    canvas.removeEventListener('touchmove', handleDrawing, false);
    canvas.removeEventListener('touchcancel', handleStopDrawing, false);
    canvas.removeEventListener('touchend', handleStopDrawing, false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStartDrawing = (event: any) => {
    //console.log('handleStartDrawing', event);

    onDrawingStart();

    event.preventDefault();

    const rect = event.target.getBoundingClientRect();

    charPosition.x = event.offsetX
      ? event.offsetX
      : event.changedTouches
      ? event.changedTouches[0].pageX - rect.left
      : 0;

    charPosition.y = event.offsetY
      ? event.offsetY
      : event.changedTouches
      ? event.changedTouches[0].pageY - rect.top
      : 0;
    pointerDown = true;
    if (!drawStartTime) {
      drawStartTime = Date.now();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDrawing = (event: any) => {
    event.preventDefault();

    const rect = event.target.getBoundingClientRect();
    const x = event.offsetX
      ? event.offsetX
      : event.changedTouches
      ? event.changedTouches[0].pageX - rect.left
      : 0;
    const y = event.offsetY
      ? event.offsetY
      : event.changedTouches
      ? event.changedTouches[0].pageY - rect.top
      : 0;
    draw(x, y);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleStopDrawing = (event: any) => {
    event.preventDefault();
    if (pointerDown) {
      pointerDown = false;

      if (charIndex >= quote.length) {
        handleComposingComplete();
      } else {
        onDrawingPaused();
      }
    }
  };

  const draw = (x: number, y: number) => {
    if (pointerDown && charIndex < quote.length) {
      const ms = Date.now() - drawStartTime;
      const seconds = Number((ms / 1000).toFixed(2));
      charDistance = getDistance(charPosition, { x, y });
      const fontSize = minFontSize + charDistance / 2;
      const letter = quote[charIndex];
      const stepSize = textWidth(letter, fontSize);
      stepSizes.push(stepSize);

      const drawChar = charDistance > stepSize;

      if (drawChar) {
        word += letter;
        const angle = Math.atan2(y - charPosition.y, x - charPosition.x);

        context.font = fontSize + 'px ' + fontName;

        context.save();
        context.translate(charPosition.x, charPosition.y);
        context.rotate(angle);
        context.fillStyle = 'black';
        context.fillText(letter, 0, 0);
        context.restore();

        charPosition.x = charPosition.x + Math.cos(angle) * stepSize;
        charPosition.y = charPosition.y + Math.sin(angle) * stepSize;

        wordPosition.push({ ...charPosition });

        const nextChar = quote[charIndex + 1];
        const isWordEnding = breakChars.indexOf(letter) > -1;
        const nextIsWordEnding = breakChars.indexOf(nextChar) > -1;
        const doWordCallback = isWordEnding && !nextIsWordEnding;

        const canvasX = charPosition.x / canvas.width;
        const canvasY = charPosition.y / canvas.height;

        if (doWordCallback) {
          onWordDraw({
            word,
            speed: charDistance,
            seconds,
            x: canvasX,
            y: canvasY,
            prevX,
            prevY,
            wentDown: canvasY > prevY,
            wentRight: canvasX > prevX,
            isLast: charIndex === quote.length - 1,
            isFirst: charIndex === 0,
          });
        }

        charIndex++;

        prevX = canvasX;
        prevY = canvasY;
      }
    }
  };

  const textWidth = (string: string, size: number) => {
    context.font = size + 'px ' + fontName;

    return context.measureText(string).width;
  };

  return <canvas ref={canvasRef} />;
};

QuoteDrawer.displayName = 'QuoteDrawer';
export default QuoteDrawer;
