import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeGraph } from '../../types';
import MainButton from '../Buttons/MainButton';

const ResetUniverse = ({
  zoomLevel,
  prunedTree,
  resetUniverse,
}: {
  zoomLevel: number;
  prunedTree: NodeGraph;
  resetUniverse: () => void;
}) => {
  const { t } = useTranslation('reset');
  const [resetClicked, setResetClicked] = useState(false);

  const startReset = () => {
    setResetClicked(true);
  };

  const confirmReset = () => {
    resetUniverse();
  };

  return (
    <Transition
      show={zoomLevel < 2 && prunedTree.nodes.length > 3}
      className="opacity-0 transition-opacity duration-[2000ms] fixed bottom-16 w-screen flex justify-center"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      unmount={false}
    >
      <Transition
        show={!resetClicked}
        className="opacity-0 transition-opacity duration-[1000ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        leave="hidden"
        appear
        unmount={false}
      >
        <MainButton onClick={startReset}>{t('reset')}</MainButton>
      </Transition>

      <Transition
        show={resetClicked}
        className="opacity-0 transition-opacity duration-[1000ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        leave="hidden"
        appear
        unmount={false}
      >
        <div>
          {t('confirm')}
          <div className="flex justify-around">
            <MainButton
              className="px-3"
              onClick={() => {
                setResetClicked(false);
              }}
            >
              {t('no')}
            </MainButton>
            <MainButton className="px-3" onClick={confirmReset}>
              {t('yes')}
            </MainButton>
          </div>
        </div>
      </Transition>
    </Transition>
  );
};

export default ResetUniverse;
