import { randBetween } from '../utils/math';
export type Note = {
  note: string;
  octave: number;
  function: number;
};

// Create some static scales
const createMajorScale: () => Note[] = () => {
  const scale = ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
  const allNotes = [];

  new Array(scale.length).fill(null).forEach((_, i) => {
    const functionIndex = i + (1 % scale.length);
    const note = scale[i % scale.length];

    new Array(4).fill(null).forEach((_, i) => {
      const octave = i + 3;
      allNotes.push({ note, octave, function: functionIndex });
    });
  });

  allNotes.push({
    note: 'C',
    octave: 7,
    function: 1,
  });

  allNotes.sort((a, b) => a.octave - b.octave);

  return allNotes;
};

const major = createMajorScale();

const dominant = major.map((note) => ({
  ...note,
  note: note.note === 'B' ? 'Bb' : note.note,
})) as Note[];

const lydian = major.map((note) => ({
  ...note,
  note: note.note === 'F' ? 'F#' : note.note,
})) as Note[];

const majorBlues = major.map((note) => ({
  ...note,
  note:
    note.note === 'E'
      ? 'Eb'
      : note.note === 'F'
      ? 'E'
      : note.note === 'B'
      ? 'Bb'
      : note.note,
  function: note.note === 'E' ? 3 : note.note === 'F' ? 3 : note.note,
})) as Note[];

export type Scale = {
  name: string;
  notes: Note[];
  functions?: number[];
};

export const scales: Scale[] = [
  {
    name: 'major',
    notes: major,
  },
  {
    name: 'dominant',
    notes: dominant,
  },
  {
    name: 'lydian',
    notes: lydian,
  },
  {
    name: 'majorBlues',
    notes: majorBlues,
  },
];

// func for getting next interval from previous note
interface GetNextIntervalProps {
  speed: number;
  prevInterval?: number;
}
export const getNextInterval = ({
  speed,
  prevInterval,
}: GetNextIntervalProps) => {
  const max = 8;

  // temp simplification, tweak speed thresholds further:
  // slower = larger interval
  // y-axis depends direction
  if (speed < 8) {
    if ((prevInterval ?? 0) > 3) {
      // 0-1
      return Math.random() < 0.2
        ? Math.floor(Math.random() * (1 - 1 + 1) + 1)
        : 1;
    } else {
      // 3-8
      return Math.floor(Math.random() * (max - 3 + 1) + 3);
    }
  } else if (speed < 12) {
    // 2-3
    return Math.floor(Math.random() * (3 - 2 + 1) + 2);
  } else {
    // 1
    return 1;
  }
};

interface GetNextNoteProps {
  scale: Scale;
  prevNote: Note;
  interval: number;
  down?: boolean;
}
// func for getting next note in scale to play
export const getNextNote = ({
  prevNote,
  interval,
  down,
  scale,
}: GetNextNoteProps) => {
  // get index of all notes
  const index = scale.notes.findIndex(
    (note) => note.note === prevNote?.note && note.octave === prevNote?.octave
  );

  if (index === -1) {
    //console.log('could not find prev Note');
    return scale.notes[randBetween(0, scale.notes.length - 1)];
  }

  const intervalValue = down ? -interval : interval;

  let noteIndex = index + intervalValue;

  const margin =
    noteIndex < 0
      ? noteIndex
      : noteIndex >= scale.notes.length
      ? noteIndex - (scale.notes.length - 1)
      : 0;

  noteIndex =
    margin === 0
      ? noteIndex
      : noteIndex < 0
      ? scale.notes.length - 1 - margin
      : margin;

  const constrained = Math.max(0, Math.min(noteIndex, scale.notes.length - 1));

  const newNote = scale.notes[constrained];

  return newNote;
};
