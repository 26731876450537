import { Node } from '../types';

export const getTranslatedQuote = (node: Node, language: string) => {
  let quoteKey: 'quote_en' | 'quote_sv' = language.includes('sv')
    ? 'quote_sv'
    : 'quote_en';

  // No quote for this language? Fall back to the alternative language.
  if (!node[quoteKey]) {
    if (quoteKey === 'quote_en') {
      quoteKey = 'quote_sv';
    } else {
      quoteKey = 'quote_en';
    }
  }

  return node[quoteKey];
};
