import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../translations/en.json';
import sv from '../translations/sv.json';

const resources = {
  en,
  sv,
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS: 'common',
  fallbackLng: 'en',
});

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

document.documentElement.lang = i18n.language;
