import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import ForceGraph2D, {
  ForceGraphMethods,
  NodeObject,
} from 'react-force-graph-2d';

import anime from 'animejs';

import { NodeGraph, Node, Link, Category, ScapeTrack } from '../../types';
import {
  constrain,
  getAngleBetween,
  getDistance,
  getMidpoint,
  getPoint,
  map,
} from '../../utils/math';
import { wrapText } from '../../utils/canvas';
import useTheme from '../../theme/useTheme';
import { sample } from '../../utils/array';
import debounce from 'lodash.debounce';
import { setScene } from '../../utils/soundScapeFunctions';
import { Player } from 'tone';
import { getSampler } from '../../utils/soundInstrumentFunctions';
import { useTranslation } from 'react-i18next';
import { sleep } from '../../utils/sleep';
import ResetUniverse from './ResetUniverse';
import { introPlinkSamples } from '../../samples/samples';
import { randBetween } from '../../utils/math';
import * as Tone from 'tone';

type GraphProps = {
  forceGraphRef: React.RefObject<ForceGraphMethods>;
  nodeToExpand: Node | null;
  setNodeToExpand: (node: Node | null) => void;
  graphData: NodeGraph;
  setSelectedNode: React.Dispatch<React.SetStateAction<Node | null>>;
  selectedNode: Node | null;
  setClickedNode: React.Dispatch<React.SetStateAction<Node | null>>;
  clickedNode: Node | null;
  triggerClick?: boolean;
  onNodeClick: (node: NodeObject) => void;
  prunedTree: NodeGraph;
  setPrunedTree: React.Dispatch<React.SetStateAction<NodeGraph>>;
  scapeTrack1: ScapeTrack;
  scapeTrack2: ScapeTrack;
  conversationTrack: {
    player?: Player;
  };
  zoomLevel: number;
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
};

const Graph = ({
  forceGraphRef,
  graphData,
  setSelectedNode,
  selectedNode,
  nodeToExpand,
  setNodeToExpand,
  clickedNode,
  setClickedNode,
  triggerClick,
  onNodeClick,
  prunedTree,
  setPrunedTree,
  scapeTrack1,
  scapeTrack2,
  conversationTrack,
  zoomLevel,
  setZoomLevel,
}: GraphProps) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const [universeResetting, setUniverseResetting] = useState(false);
  const universeResettingNodeTransform = useRef<{ scale: number }>({
    scale: 1.0,
  });
  const universeResettingColorNodes = useRef(false);

  const introPlinkTrackRef = useRef<{
    player?: Player;
  }>({});

  const debouncedZoomOut = debounce(() => {
    zoomOut();
  }, 500);

  const debouncedSoundUpdate = debounce(() => {
    updateSoundPanning();
    updateNodeSoundVolume();
  }, 500);

  const debouncedZoomUpdate = debounce((z: number) => {
    //console.log('debounced zoom update', z);
    setZoomLevel(z);
  }, 500);

  const { i18n, t } = useTranslation('category');

  const { getTextColor, colors, applyTheme, setHeaderOpacity } = useTheme();
  const rootId = 0;

  useEffect(() => {
    setPrunedTree(getPrunedTree());
    applyTheme('dark');
  }, []);

  useEffect(() => {
    if (!selectedNode) {
      setClickedNode(null);
    }
  }, [selectedNode]);

  const createLink = (
    source: Node,
    target: Node,
    category: Category
  ): Link => ({
    source: source.id,
    target: target.id,
    cat: category,
    distance: 20, // TODO
  });

  const createRootChildLinks = (rootNode: Partial<Node>) => {
    rootNode.used = true;
    rootNode.childLinks = [];
    const node = rootNode as Node;
    node.root = true;

    const threeKids = [0, 0, 1][Math.floor(Math.random() * 3)];

    const randomCategories = sample(node.tags, 2 + threeKids);
    randomCategories.forEach((category) => {
      const categoryNodes = graphData.nodes.filter(
        (node) => !node.used && node.tags!.includes(category)
      );
      const randomChildNode =
        categoryNodes[Math.floor(Math.random() * categoryNodes.length)];
      randomChildNode.used = true;
      randomChildNode.parentLinkCategory = category;
      randomChildNode.parent = rootNode as Node;
      randomChildNode.x = rootNode.x;
      randomChildNode.y = rootNode.y;

      const link = createLink(node, randomChildNode as Node, category);
      node.childLinks.push(link);
    });
  };

  const setupChildLinks = (node: Node) => {
    node.childLinks = [];
    const linkOfParentType = createChildLink(node, node.parentLinkCategory!);

    if (linkOfParentType) {
      node.childLinks.push(linkOfParentType);
    }
    const threeKids = [0, 0, 1][Math.floor(Math.random() * 3)];
    const randomCategories = sample(
      node.tags.filter((tag) => tag !== node.parentLinkCategory!),
      linkOfParentType ? 1 + threeKids : 2 + threeKids
    );

    randomCategories.forEach((category) => {
      const link = createChildLink(node, category);
      if (link) node.childLinks.push(link);
    });
  };

  const createChildLink = (parent: Node, category: Category): Link | null => {
    const availableNodes = graphData.nodes.filter(
      (node) => !node.used && node.tags!.includes(category)
    );

    if (availableNodes.length === 0) return null;

    const node =
      availableNodes[Math.floor(Math.random() * availableNodes.length)];
    node.used = true;
    node.parentLinkCategory = category;
    node.parent = parent;
    node.x = parent.x;
    node.y = parent.y;
    return createLink(parent, node as Node, category);
  };

  const nodesById = useMemo(() => {
    const nodesById = Object.fromEntries(
      graphData.nodes.map((node) => {
        // Create child links
        if (node.id === rootId) {
          createRootChildLinks(node);
        }
        node.author = node.author?.toUpperCase();
        return [node.id, node];
      })
    );
    //console.log('Created nodesById:', nodesById);
    return nodesById;
  }, [graphData]);

  const getPrunedTree = useCallback(() => {
    const visibleNodes = [];
    const visibleLinks = [];
    (function traverseTree(node = nodesById[rootId]) {
      visibleNodes.push(node);
      if (node.collapsed) return;
      visibleLinks.push(...node.childLinks);
      node.childLinks
        .map((link: Link) => {
          return typeof link.target === 'object'
            ? link.target
            : nodesById[link.target];
        })
        .forEach(traverseTree);
    })();
    return { nodes: visibleNodes, links: visibleLinks };
  }, [nodesById]);

  const resetUniverse = async () => {
    turnOffNodes();
    if (scapeTrack1.player?.state === 'started') {
      setScene(scapeTrack1.player, 'exit');
    } else {
      scapeTrack2.player && setScene(scapeTrack2.player, 'exit');
    }

    conversationTrack.player &&
      setScene(conversationTrack.player, 'foregound', 2);
    const plinks = Object.values(introPlinkSamples);

    introPlinkTrackRef.current.player = new Tone.Player(
      plinks[randBetween(0, plinks.length - 1)]
    );
    setUniverseResetting(true);
    forceGraphRef.current?.zoomToFit(3000, width / 3);

    await sleep(3000);

    universeResettingColorNodes.current = true;

    await sleep(500);

    anime({
      targets: universeResettingNodeTransform.current,
      scale: 2 * prunedTree.nodes.length,
      easing: 'easeInOutQuad',
      duration: 3000,
    });

    await sleep(3500);

    setSelectedNode(null);
    setClickedNode(null);
    setNodeToExpand(null);
    graphData.nodes.forEach((node) => {
      node.parent = undefined;
      node.parentLinkCategory = undefined;
      node.childLinks = [];
      node.used = false;
      node.composed = false;
      node.collapsed = true;
      node.sound?.sampler?.dispose();
      node.sound?.samplerLoop?.dispose();
      node.sound?.part?.dispose();
      node.sound?.loop?.dispose();
      node.sound = undefined;
    });
    const rootNode = graphData.nodes[0];
    createRootChildLinks(rootNode);
    setPrunedTree(getPrunedTree());

    await sleep(400);

    anime({
      targets: universeResettingNodeTransform.current,
      scale: 1,
      easing: 'easeInOutQuad',
      duration: 1000,
    });
    forceGraphRef.current?.centerAt(rootNode.x, rootNode.y, 2000);

    await sleep(1000);

    forceGraphRef.current?.zoom(8, 1000);
    introPlinkTrackRef.current.player &&
      setScene(introPlinkTrackRef.current.player, 'enter', 0.1);
    conversationTrack.player &&
      setScene(conversationTrack.player, 'farback', 0.1);
    setUniverseResetting(false);

    await sleep(100);

    universeResettingColorNodes.current = false;
  };

  useEffect(() => {
    if (nodeToExpand && nodeToExpand.collapsed) {
      //console.log('Expanding node:', nodeToExpand.id);
      nodeToExpand.collapsed = false;

      if (nodeToExpand.id !== rootId) setupChildLinks(nodeToExpand);

      setPrunedTree(getPrunedTree());
    }
  }, [nodeToExpand]);

  const handleNodeClick = useCallback(
    (nodeObj: NodeObject) => {
      nodeObj && onNodeClick(nodeObj);
      const node = nodeObj as Node;
      //console.log('Clicked node:', node);
      setClickedNode(node);

      forceGraphRef.current?.centerAt(node.x, node.y, 500);
      setTimeout(() => {
        setSelectedNode(node as Node);
      }, 500);
    },
    [forceGraphRef, width, height, prunedTree]
  );

  useEffect(() => {
    if (triggerClick && prunedTree) {
      const firstNode = prunedTree.nodes[0];
      handleNodeClick(firstNode);
    }
  }, [triggerClick, prunedTree]);

  const ping = useRef({
    angle: 0,
    scale: 0,
    opacity: 1,
  });

  useEffect(() => {
    setHeaderOpacity(0);
    anime({
      targets: ping.current,
      angle: 1,
      scale: 1,
      opacity: 0,
      easing: 'easeInOutQuad',
      loop: true,
      duration: 1800,
    });
  }, []);

  const onResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    //console.log('forceGraphRef.current:', forceGraphRef.current);

    if (forceGraphRef.current) {
      forceGraphRef.current.zoom(8, 1000);
      forceGraphRef.current
        .d3Force('link')
        ?.distance((link: Link) => link.distance);
    }
  }, []);

  const drawPing = (ctx: CanvasRenderingContext2D, node: Node) => {
    ctx.save();

    const nodeScreenCoordinates = forceGraphRef.current?.graph2ScreenCoords(
      node.x!,
      node.y!
    );

    const distanceToCenterOfScreen =
      Math.abs(nodeScreenCoordinates!.x - width / 2) +
      Math.abs(nodeScreenCoordinates!.y - height / 2);
    const opacity = map(distanceToCenterOfScreen, 0, width, 1, 0);

    ctx.globalAlpha = opacity;
    for (let i = 1; i < 3; i++) {
      ctx.beginPath();
      ctx.lineWidth = 0.1;
      ctx.strokeStyle = `rgba(255,255,255,${ping.current.opacity})`;
      ctx.arc(
        node.x!,
        node.y!,
        10 * ping.current.scale + i * 2,
        0,
        Math.PI * 2
      );
      ctx.stroke();
      ctx.closePath();
    }
    ctx.restore();
  };

  const drawNode = (
    ctx: CanvasRenderingContext2D,
    node: Node,
    globalScale: number
  ) => {
    if (node.x === undefined || node.y === undefined) return;
    ctx.save();
    if (node.collapsed) {
      if (globalScale > 3.5 && clickedNode?.id !== node.id) {
        const radgrad = ctx.createRadialGradient(
          node.x! - 0.7,
          node.y! - 0.7,
          0,
          node.x! - 0.7,
          node.y! - 0.7,
          3.4
        );
        radgrad.addColorStop(0, 'rgba(235, 50, 35,1)');
        radgrad.addColorStop(0.4, 'rgba(244, 183, 250,1)');
        radgrad.addColorStop(0.67, 'rgba(235, 50, 35,.5)');
        radgrad.addColorStop(1, 'rgba(235, 50, 35,0)');
        ctx.fillStyle = radgrad;
        ctx.fillRect(node.x! - 10, node.y! - 10, 20, 20);
      }

      ctx.beginPath();
      ctx.arc(
        node.x!,
        node.y!,
        universeResettingNodeTransform.current.scale * 2.8,
        0,
        Math.PI * 2
      );
      ctx.closePath();
      ctx.fillStyle = clickedNode?.id === node.id ? colors.white : colors.red;
      ctx.fill();
      if (globalScale > 2.5 && clickedNode?.id !== node.id) {
        /*  const innerShadow = ctx.createRadialGradient(
          node.x! + 2,
          node.y! + 2,
          0,
          node.x!,
          node.y!,
          4.5
        );
        innerShadow.addColorStop(0, 'rgba(0,0,0,0.5)');
        innerShadow.addColorStop(1, 'rgba(0,0,0,0)');
        ctx.fillStyle = innerShadow;
        ctx.fillRect(node.x! - 10, node.y! - 10, 20, 20);
     */
      }
    } else {
      ctx.beginPath();
      ctx.arc(
        node.x!,
        node.y!,
        universeResettingNodeTransform.current.scale * 4,
        0,
        Math.PI * 2
      );
      ctx.closePath();
      ctx.fillStyle =
        node.collapsed || universeResettingColorNodes.current
          ? colors.red
          : colors.white;
      ctx.fill();

      if (!selectedNode && globalScale >= 5) {
        const opacity = map(globalScale, 5, 8, 0, 1);
        ctx.globalAlpha = opacity;

        let nodeLabel = node.author;
        let scale = 1;
        if (!nodeLabel) {
          nodeLabel = i18n.language.includes('sv')
            ? node.source_sv!
            : node.source_en!;
        }
        const font = `1px Alfred Sans`;
        if (node.author) {
          const longestWordInName = nodeLabel
            .split(' ')
            .reduce((a, b) => (a.length > b.length ? a : b));
          if (longestWordInName?.length > 10) {
            //font = `0.8px Alfred Sans`;
            scale = 0.8;
            ctx.scale(scale, scale);
          }
        }

        ctx.font = font;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        ctx.fillStyle = colors.gold;
        const textHeight = wrapText(
          ctx,
          nodeLabel + '\n' + node.year,
          node.x! / scale,
          node.y! / scale,
          6.3,
          1.1,
          true
        );
        wrapText(
          ctx,
          nodeLabel + '\n' + node.year,
          node.x! / scale,
          (node.y! - textHeight / 2 + 0.4) / scale,
          6.3,
          1.1,
          false
        );
      }
    }

    ctx.restore();
  };

  const drawLink = (
    ctx: CanvasRenderingContext2D,
    source: Node,
    target: Node,
    globalScale: number
  ) => {
    ctx.save();

    const sourceCoords = { x: source.x!, y: source.y! };
    const targetCoords = { x: target.x!, y: target.y! };
    const distance = getDistance(sourceCoords, targetCoords);
    const endPoint = target.collapsed
      ? getPoint(
          sourceCoords,
          targetCoords,
          Math.max(Math.max(0, distance - 10), 6)
        )
      : targetCoords;

    ctx.strokeStyle = universeResettingColorNodes.current
      ? colors.red
      : colors.white;
    ctx.beginPath();
    ctx.lineWidth = constrain(
      Math.abs(5 - map(globalScale, 1, 200, 5, 0.1, true)),
      0.16,
      0.5
    );

    ctx.moveTo(sourceCoords.x, sourceCoords.y);
    ctx.lineTo(endPoint.x, endPoint.y);

    ctx.stroke();
    ctx.closePath();

    ctx.restore();
  };

  const drawExplorableLinkLabel = (
    ctx: CanvasRenderingContext2D,
    source: Node,
    target: Node,
    globalScale: number
  ) => {
    ctx.save();

    const sourceCoords = { x: source.x!, y: source.y! };
    const targetCoords = { x: target.x!, y: target.y! };

    const distance = getDistance(sourceCoords, targetCoords);

    const labelCoords = getPoint(sourceCoords, targetCoords, distance - 7);
    ctx.translate(labelCoords.x, labelCoords.y);

    const radgrad = ctx.createRadialGradient(0, 0, 0, 0, 0, 2);
    radgrad.addColorStop(0, 'rgba(255, 253, 84,1)');
    radgrad.addColorStop(0.5, 'rgba(255, 253, 84,.2)');
    radgrad.addColorStop(1, 'rgba(255, 253, 84,0)');

    const opacity = map(globalScale, 5, 8, 0, 1);
    ctx.globalAlpha = opacity;

    ctx.fillStyle = radgrad;
    ctx.fillRect(-6, -6, 12, 12);

    ctx.fillStyle = colors.yellow;
    ctx.beginPath();
    ctx.arc(0, 0, 1, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    const fontSize = 2;
    ctx.font = `${fontSize}px Alfred Sans`;
    ctx.textBaseline = 'middle';
    if (sourceCoords.x < targetCoords.x) {
      ctx.textAlign = 'left';
      ctx.fillText(t(target.parentLinkCategory!), 2, 0.2);
    } else {
      ctx.textAlign = 'right';
      ctx.fillText(t(target.parentLinkCategory!), -2, 0.2);
    }
    ctx.restore();
  };

  const drawLinkConnectionLabel = (
    ctx: CanvasRenderingContext2D,
    source: Node,
    target: Node,
    globalScale: number
  ) => {
    ctx.save();

    const sourceCoords = { x: source.x!, y: source.y! };
    const targetCoords = { x: target.x!, y: target.y! };

    const labelCoords = getMidpoint(sourceCoords, targetCoords);

    ctx.translate(labelCoords.x, labelCoords.y);

    const radgrad = ctx.createRadialGradient(0, 0, 0, 0, 0, 2);
    radgrad.addColorStop(0, 'rgba(255, 253, 84,1)');
    radgrad.addColorStop(0.5, 'rgba(255, 253, 84,.2)');
    radgrad.addColorStop(1, 'rgba(255, 253, 84,0)');

    const opacity = map(globalScale, 10, 20, 0, 1);
    ctx.globalAlpha = opacity;

    ctx.fillStyle = radgrad;
    ctx.fillRect(-6, -6, 12, 12);

    ctx.fillStyle = colors.yellow;
    ctx.beginPath();
    ctx.arc(0, 0, 1, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.fill();

    const angle = getAngleBetween(sourceCoords, targetCoords);
    const angleOffset = angle > 0 && angle < 180 ? -90 : 90;
    ctx.rotate((angleOffset + angle) * (Math.PI / 180));

    const fontSize = 2;
    ctx.font = `${fontSize}px Alfred Sans`;
    ctx.textBaseline = 'middle';
    if (sourceCoords.x < targetCoords.x) {
      ctx.textAlign = 'left';
      ctx.fillText(t(target.parentLinkCategory!), 2, 0.2);
    } else {
      ctx.textAlign = 'right';
      ctx.fillText(t(target.parentLinkCategory!), -2, 0.2);
    }
    ctx.restore();
  };

  const drawLinkConnection = (
    ctx: CanvasRenderingContext2D,
    source: Node,
    target: Node,
    color: string
  ) => {
    ctx.save();
    ctx.fillStyle = getTextColor();

    const sourceCoords = { x: source.x!, y: source.y! };
    const targetCoords = { x: target.x!, y: target.y! };
    const point = getPoint(sourceCoords, targetCoords, 5.5);
    const angle = getAngleBetween(sourceCoords, targetCoords);

    ctx.translate(point.x, point.y);
    ctx.rotate((-90 + angle) * (Math.PI / 180));

    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.moveTo(-0.1, 0);
    ctx.arcTo(0, -1.5, -2, -1.91, 2.0);
    ctx.lineTo(0, -1.5);

    ctx.moveTo(0, 0);
    ctx.arcTo(0, -1, 2, -2.1, 2.5);
    ctx.lineTo(0, -1.5);
    ctx.fill();
    ctx.closePath();

    ctx.restore();
  };

  useEffect(() => {
    if (clickedNode) {
      debouncedSoundUpdate.cancel();
      debouncedZoomOut.cancel();

      turnOffNodes();
      if (conversationTrack.player?.state === 'started') {
        setScene(conversationTrack.player, 'exit');
      }
    } else {
      debouncedSoundUpdate();
    }
  }, [clickedNode]);

  const updateSoundPanning = () => {
    if (clickedNode || universeResetting) {
      return;
    }
    //console.log('updateSoundPanning');
    prunedTree?.nodes.forEach((node) => {
      const nodeScreenCoordinates = forceGraphRef.current?.graph2ScreenCoords(
        node.x!,
        node.y!
      );

      if (nodeScreenCoordinates) {
        const xDistanceToCenterOfScreen = nodeScreenCoordinates.x - width / 2;
        const xParallax = map(
          xDistanceToCenterOfScreen,
          -width / 2,
          width / 2,
          -1,
          1
        );
        node.sound?.panner.pan.setValueAtTime(xParallax, 0.1);
      }
    });
  };

  const turnOffNodes = () => {
    prunedTree.nodes?.forEach((n) => {
      if (n.sound?.samplerLoop && !n.sound.samplerLoop.disposed) {
        //console.log('ramping', n.sound.samplerLoop.volume.value);
        n.sound.samplerLoop.volume.rampTo(-50, 0.5, '+0');
        setTimeout(() => {
          n.sound?.loop?.stop();
          n.sound?.samplerLoop?.dispose();
          //console.log('samplerLoop disconnected and loop stopped');
        }, 600);
      }
    });
  };

  const zoomOut = () => {
    if (conversationTrack.player?.state === 'started') {
      return;
    }

    //console.log('zoomOut');
    turnOffNodes();

    if (scapeTrack1.player?.state === 'started') {
      setScene(scapeTrack1.player, 'farback', 3);
      conversationTrack.player &&
        setScene(conversationTrack.player, 'enter', 3, 3, true, true);
    } else {
      scapeTrack2.player && setScene(scapeTrack2.player, 'farback', 3);
      conversationTrack.player &&
        setScene(conversationTrack.player, 'enter', 3, 3, true);
    }
  };

  const zoomIn = () => {
    if (
      scapeTrack1.player?.state === 'started' &&
      scapeTrack1.player?.volume.value < -22
    ) {
      setScene(scapeTrack1.player, 'foreground', 3);
      conversationTrack.player && setScene(conversationTrack.player, 'exit');
    } else if (
      scapeTrack2.player?.state === 'started' &&
      scapeTrack2.player?.volume.value < -22
    ) {
      scapeTrack2.player && setScene(scapeTrack2.player, 'foreground', 3);
      conversationTrack.player && setScene(conversationTrack.player, 'exit');
      //console.log('zooming in');
    }
  };

  const updateNodeSoundVolume = () => {
    if (clickedNode || universeResetting) {
      return;
    }

    //console.log('updateNodeSoundVolume');
    zoomIn();

    prunedTree?.nodes.forEach((node) => {
      const nodeScreenCoordinates = forceGraphRef.current?.graph2ScreenCoords(
        node.x!,
        node.y!
      );

      if (nodeScreenCoordinates) {
        const distanceToCenterOfScreen =
          Math.abs(nodeScreenCoordinates.x - width / 2) +
          Math.abs(nodeScreenCoordinates.y - height / 2);
        let volume = map(distanceToCenterOfScreen, 0, width, -10, -50);

        //temp solution for when node is outside screen
        if (volume > -10) {
          volume = -50;
        }
        node.sound?.samplerLoop?.volume.rampTo(volume, 0.2, '+0');

        if (
          volume < -30 &&
          node.sound?.samplerLoop &&
          !node.sound.samplerLoop.disposed
        ) {
          node.sound?.samplerLoop?.volume.rampTo(-100, 0.5, '+0');
          setTimeout(() => {
            node.sound?.loop?.stop();
            node.sound?.samplerLoop?.dispose();
          }, 600);
          /*  console.log(
            'in updateSoundVolume: samplerLoop disconnected and loop stopped'
          ); */
        }
        if (
          volume > -30 &&
          node.sound?.samplerLoop?.disposed &&
          node.childLinks
        ) {
          const { sampler } = getSampler(
            node.parentLinkCategory ?? node.childLinks[0].cat
          );

          setTimeout(() => {
            sampler.volume.value = -50;
            if (node.sound?.samplerLoop) {
              node.sound.samplerLoop = sampler;
              node.sound.samplerLoop.connect(node.sound.panner);
              node.sound.loop?.start();
              node.sound.samplerLoop.volume.rampTo(volume, 0.5, '+0');
              /* console.log(
                'in updateSoundVolume: samplerLoop connected and loop started'
              ); */
            }
          }, 500);
        }
      }
    });
  };

  return (
    <>
      <ForceGraph2D
        minZoom={1}
        maxZoom={200}
        linkWidth={2}
        height={height}
        width={width}
        linkColor={getTextColor}
        backgroundColor="transparent"
        autoPauseRedraw={false}
        graphData={prunedTree}
        ref={forceGraphRef as React.MutableRefObject<ForceGraphMethods>}
        linkCanvasObjectMode={() => 'replace'}
        d3VelocityDecay={0.9}
        d3AlphaDecay={0.05}
        enablePanInteraction={prunedTree.nodes.length > 1 && !universeResetting}
        enableZoomInteraction={
          prunedTree.nodes.length > 1 && !universeResetting
        }
        enableNodeDrag={prunedTree.nodes.length > 1 && !universeResetting}
        linkCanvasObject={(link, ctx, globalScale) => {
          const source = link.source as Node;
          const target = link.target as Node;

          if (source && target) {
            drawLink(ctx, source, target, globalScale);
            if (globalScale >= 1.5) {
              drawLinkConnection(
                ctx,
                source,
                target,
                universeResettingColorNodes.current ? colors.red : colors.white
              );
              if (!target.collapsed) {
                drawLinkConnection(
                  ctx,
                  target,
                  source,
                  universeResettingColorNodes.current
                    ? colors.red
                    : colors.white
                );
              }
            }
          }
        }}
        nodeCanvasObjectMode={() => 'replace'}
        nodeCanvasObject={(nodeObj, ctx, globalScale) => {
          const node = nodeObj as Node;
          if (!node.collapsed) {
            drawPing(ctx, node);
          }
          drawNode(ctx, node, globalScale);
          if (!selectedNode && node.parent && globalScale >= 5) {
            if (node.collapsed) {
              drawExplorableLinkLabel(ctx, node.parent, node, globalScale);
            } else if (globalScale >= 10) {
              drawLinkConnectionLabel(ctx, node.parent, node, globalScale);
            }
          }
        }}
        onNodeDrag={() => debouncedSoundUpdate()}
        onZoom={({ k }) => {
          debouncedZoomUpdate(k);
          if (k < 3) {
            debouncedSoundUpdate.cancel();
            debouncedZoomOut();
            return;
          } else {
            debouncedZoomOut.cancel();
            debouncedSoundUpdate();
          }
        }}
        nodeColor={(node) =>
          (node as Node).collapsed ? getTextColor() : 'yellow'
        }
        onNodeClick={(node) => {
          if (!universeResetting && zoomLevel > 4) handleNodeClick(node);
        }}
        enablePointerInteraction={zoomLevel > 4}
      />
      {!universeResetting && (
        <ResetUniverse
          zoomLevel={zoomLevel}
          prunedTree={prunedTree}
          resetUniverse={resetUniverse}
        />
      )}
    </>
  );
};

export default Graph;
