import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTheme from '../../theme/useTheme';
import { enableScroll } from '../../utils/prevent-scroll';

import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import RedShape from '../../assets/shapes/red-blur.png';
import PinkShape from '../../assets/shapes/pink-blur.png';

type IntroProps = {
  onComplete: () => void;
  loading: boolean;
};

const Intro = ({ onComplete, loading }: IntroProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [scrolledEnoughForThemeChange, setScrolledEnoughForThemeChange] =
    useState(false);
  const [scrolledEnoughForAutoStart, setScrolledEnoughForAutoStart] =
    useState(false);
  const [showScrollNudge, setShowScrollNudge] = useState(false);
  const [autoStart, setAutoStartWhenLoadingComplete] = useState(false);

  const landingRef = useRef<HTMLDivElement>(null);
  const scrollNudgeRef = useRef<NodeJS.Timeout | null>(null);

  const { t } = useTranslation('onboarding');
  const { applyTheme, setHeaderOpacity } = useTheme();

  useEffect(() => {
    enableScroll();
    setHeaderOpacity(0.8);
    scrollNudgeRef.current = setTimeout(() => {
      setShowScrollNudge(true);
    }, 2000);

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    //console.log('loading:', loading);
    if (!loading && autoStart) {
      start();
    }
  }, [loading]);

  const scrollHandler = () => {
    if (scrollNudgeRef.current) clearTimeout(scrollNudgeRef.current);

    if (window.scrollY > 450 && !scrolledEnoughForThemeChange) {
      setScrolledEnoughForThemeChange(true);
      applyTheme('burgundy');
    }

    if (window.scrollY > 550) {
      setScrolledEnoughForAutoStart(true);
      window.removeEventListener('scroll', scrollHandler);
      if (!loading) {
        start();
      } else {
        setAutoStartWhenLoadingComplete(true);
      }
    }
  };

  const start = () => {
    applyTheme('dark');
    setTimeout(() => setIsClosing(true), 1000);
    setTimeout(onComplete, 2000);
  };

  return (
    <Transition
      show={!isClosing}
      ref={landingRef}
      appear
      className={'opacity-100 transition-opacity duration-1000'}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="flex flex-col relative h-full justify-between">
        <p className="preamble animate-fadeInDelayed pt-24 px-5 max-w-[578px] min-h-[200vh] md:m-auto md:text-[50px] md:leading-[58px] md:mt-[20vh] whitespace-pre-line">
          {t('intro1')}
        </p>
      </div>

      <Transition
        appear
        show={!scrolledEnoughForThemeChange}
        className="transition-all duration-[2000ms] scale-100 fixed flex justify-center pointer-events-none bottom-[40%] animate-fadeIn overflow-visible w-screen h-auto"
        leaveFrom="scale-100 "
        leaveTo="scale-0 bottom-[50%]"
      >
        <div className="absolute intro-pink-thing">
          <img
            alt=""
            className={`absolute z-10 -translate-x-[5%] -translate-y-[20%] md:-translate-y-[40%] md:-translate-x-[50%] rotate-[270deg] max-w-none`}
            src={PinkShape}
          />
        </div>

        <div className="absolute intro-red-circle bg-red rounded-full top-12 md:left-1/2 w-96 h-96 md:w-[512px] md:h-[512px]" />
        <img
          alt=""
          className={`absolute -translate-x-[5%] -translate-y-[20%] md:-translate-y-[15%] md:translate-x-[30%] scale-[1.8] md:scale-x-[1.85] md:scale-y-[2.5] rotate-[270deg] w-[700px] max-w-none`}
          src={RedShape}
        />
      </Transition>
      <Transition
        show={showScrollNudge || scrolledEnoughForThemeChange}
        appear
        className="fixed transition-opacity duration-1000 flex flex-col bottom-10 left-1/2 -translate-x-1/2 text-white"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {!scrolledEnoughForAutoStart ? (
          <>
            <div>{t('scroll', { ns: 'nudges' })}</div>
            <div className="m-auto mt-2">
              <Arrow />
            </div>
          </>
        ) : loading ? (
          <div>{t('loading', { ns: 'common' })}</div>
        ) : (
          <>{/* Should auto start */}</>
        )}
      </Transition>
    </Transition>
  );
};

export default Intro;
