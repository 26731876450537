import { useCallback, useContext, useRef } from 'react';
import { Theme } from '../types';
import { ThemeContext } from './ThemeProvider';

type ColorMap = {
  [c in Theme]: string;
};

const useTheme = () => {
  const { activeTheme, applyTheme } = useContext(ThemeContext);

  const root = document.documentElement;
  const style = getComputedStyle(root);
  const colors = useRef<ColorMap>({
    black: style.getPropertyValue('--black'),
    dark: style.getPropertyValue('--dark'),
    white: style.getPropertyValue('--white'),
    grey: style.getPropertyValue('--grey'),
    yellow: style.getPropertyValue('--yellow'),
    gold: style.getPropertyValue('--gold'),
    pink: style.getPropertyValue('--pink'),
    red: style.getPropertyValue('--red'),
    burgundy: style.getPropertyValue('--burgundy'),
  });

  const getTextColor = useCallback(() => {
    const root = document.documentElement;
    const style = getComputedStyle(root);

    const textColor = style.getPropertyValue('--text-base');
    return textColor;
  }, [activeTheme]);

  const getBackgroundColor = useCallback(() => {
    const root = document.documentElement;
    const style = getComputedStyle(root);

    const bgColor = style.getPropertyValue('--base-background');
    return bgColor;
  }, [activeTheme]);

  const setHeaderOpacity = (opacity: number) => {
    const root = document.documentElement;
    root.style.setProperty('--header-opacity', `${opacity}`);
  };

  return {
    applyTheme,
    getTextColor,
    getBackgroundColor,
    setHeaderOpacity,
    activeTheme,
    colors: colors.current!,
  };
};

export default useTheme;
