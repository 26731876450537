import { Vector2 } from '../types';

export const getDistance = (pt: Vector2, pt2: Vector2) => {
  let xs = 0;
  let ys = 0;

  xs = pt2.x - pt.x;
  xs = xs * xs;

  ys = pt2.y - pt.y;
  ys = ys * ys;

  return Math.sqrt(xs + ys);
};

export const constrain = (n: number, low: number, high: number) => {
  return Math.max(Math.min(n, high), low);
};

export const getMidpoint = (pt1: Vector2, pt2: Vector2) => ({
  x: (pt1.x + pt2.x) / 2,
  y: (pt1.y + pt2.y) / 2,
});

export const getPoint = (pt1: Vector2, pt2: Vector2, distance: number) => {
  const d = Math.sqrt(Math.pow(pt2.x - pt1.x, 2) + Math.pow(pt2.y - pt1.y, 2));
  const t = distance / d;
  const point = {
    x: (1 - t) * pt1.x + t * pt2.x,
    y: (1 - t) * pt1.y + t * pt2.y,
  };
  return point;
};

export const getAngleBetween = (pt1: Vector2, pt2: Vector2) =>
  Math.atan2(pt2.y - pt1.y, pt2.x - pt1.x) * (180 / Math.PI);

export const map = (
  n: number,
  start1: number,
  stop1: number,
  start2: number,
  stop2: number,
  withinBounds = true
) => {
  const newval = ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  if (!withinBounds) {
    return newval;
  }
  if (start2 < stop2) {
    return constrain(newval, start2, stop2);
  } else {
    return constrain(newval, stop2, start2);
  }
};

export const randBetween = (
  min = 0,
  max = 1,
  exceptions: number[] = []
): number => {
  const exceptionsArr = Array.isArray(exceptions) ? exceptions : [exceptions];
  const rand = Math.floor(Math.random() * (max - min + 1) + min);

  return exceptionsArr.indexOf(rand) > -1
    ? randBetween(min, max, exceptionsArr)
    : rand;
};
