import { ReactComponent as HamburgerIcon } from '../../assets/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

const Hamburger = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <div className="flex justify-around flex-col flex-nowrap w-6 h-6 m-auto">
      {isOpen ? <CloseIcon /> : <HamburgerIcon />}
    </div>
  );
};

export default Hamburger;
