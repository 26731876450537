export const preventPinchToZoom = () => {
  window.addEventListener(
    'touchstart',
    (e) => {
      if (e.touches?.length > 1) {
        e.preventDefault();
      }
    },
    { passive: false }
  );
};
