import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../components/Buttons/MainButton';
import useTheme from '../../theme/useTheme';
import { useSearchParams } from 'react-router-dom';

import { unmute } from '../../utils/unmute';

import { ReactComponent as NobelLogo } from '../../assets/wordmarks/nobel-prize-museum-main.svg';
import { ReactComponent as LifeEternalLogoSV } from '../../assets/wordmarks/life-eternal_sv.svg';
import { ReactComponent as LifeEternalLogoEN } from '../../assets/wordmarks/life-eternal_en.svg';
import RedShape from '../../assets/shapes/red-blur.png';
import LanguagePicker from '../../components/Menu/LanguagePicker';

type LandingProps = {
  onLandingComplete: () => void;
  loading: boolean;
  context: AudioContext | null;
};

const Landing = ({ context, loading, onLandingComplete }: LandingProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [showEternalLogo, setShowEternalLogo] = useState(false);

  const { t, i18n } = useTranslation('landing');
  const { applyTheme, setHeaderOpacity } = useTheme();

  const [searchParams] = useSearchParams();

  const kiosk = searchParams.get('kiosk');

  useEffect(() => {
    applyTheme('pink');
    setHeaderOpacity(0);
    unmute(context);
    setTimeout(() => setShowEternalLogo(true), 3000);
  }, []);

  const handleClick = () => {
    unmute(context);

    setIsClosing(true);
    onLandingComplete();
  };

  return (
    <div className="h-full flex flex-col">
      <main className="flex-grow">
        <Transition
          show={!isClosing}
          className="flex flex-col relative h-full min-h-content justify-between opacity-100 transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {kiosk && (
            <div className="z-20">
              <LanguagePicker />
            </div>
          )}

          <div className="absolute flex flex-col justify-center items-center overflow-hidden w-full h-full">
            <button
              aria-label={loading ? t('loading', { ns: 'common' }) : t('start')}
              disabled={loading}
              onClick={handleClick}
              className="flex items-center aspect-square justify-center rounded-full bg-red w-96 h-96 max-w-[80vmin] max-h-[80vmin] md:w-[512px] md:h-[512px]  z-10"
            >
              <div className="moving-2 absolute">
                <img
                  alt=""
                  className={`-translate-x-[5%] -translate-y-[5%] scale-[1.3] md:scale-[1.85] rotate-[270deg] w-[700px] max-w-none`}
                  src={RedShape}
                />
              </div>
              <div className="flex text-white relative justify-center w-[300px]">
                <Transition
                  className="absolute -translate-y-1/2 opacity-100 transition-opacity duration-[2000ms]"
                  show={!showEternalLogo}
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <NobelLogo />
                </Transition>

                <Transition
                  show={showEternalLogo}
                  className="absolute -translate-y-1/2 scale-[2] opacity-0 transition-opacity duration-[3000ms] delay-[1000ms]"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {i18n.language.includes('sv') ? (
                    <LifeEternalLogoSV />
                  ) : (
                    <LifeEternalLogoEN />
                  )}
                </Transition>
              </div>
            </button>
          </div>
          <div
            className={`flex flex-col items-center justify-center mt-auto pb-[48px] pt-20 z-10`}
          >
            {' '}
            {!kiosk && <div>{t('headphones', { ns: 'nudges' })}</div>}
            <div className={` ${loading ? 'animate-pulse' : ''}`}>
              <MainButton disabled={loading} onClick={handleClick}>
                {loading ? t('loading', { ns: 'common' }) : t('start')}
              </MainButton>
            </div>
          </div>
        </Transition>
      </main>
    </div>
  );
};

export default Landing;
