export const wrapText = (
  context: CanvasRenderingContext2D,
  text: string,
  x: number,
  y: number,
  maxWidth: number,
  lineHeight: number,
  measureOnly: boolean
) => {
  let height = 0;
  const paragraphs = text.split('\n');

  for (let ii = 0; ii < paragraphs.length; ii++) {
    let line = '';
    const words = paragraphs[ii].split(' ');

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth) {
        if (!measureOnly) context.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
        height += lineHeight;
      } else {
        line = testLine;
      }
    }

    if (!measureOnly) context.fillText(line, x, y);
    y += lineHeight * 1.5;
    height += lineHeight;
  }
  height += lineHeight;
  return height;
};
