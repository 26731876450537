import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import ThemeProvider from './theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { preventPinchToZoom } from './utils/prevent-pinch-to-zoom';
import { disableScroll } from './utils/prevent-scroll';

function App() {
  const { i18n, t } = useTranslation('common');

  useEffect(() => {
    document.title = t('title_long');
  }, [i18n.language]);

  useEffect(() => {
    preventPinchToZoom();
    disableScroll();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
