import { useEffect, useMemo, useRef, useState } from 'react';
import { ForceGraphMethods } from 'react-force-graph-2d';
import ComposingView from '../../components/Graph/ComposingView';
import Graph from '../../components/Graph/Graph';
import useCompressor from '../../hooks/useCompressor';
import * as Tone from 'tone';
import nodes from '../../data/quote-graph.json';
import { Node, NodeGraph } from '../../types';
import Landing from '../Landing/Landing';
import conversation from '../../samples/conversation/conversation.mp3';
import useTheme from '../../theme/useTheme';
import Intro from '../Intro/Intro';
import { randBetween } from '../../utils/math';
import { fxNode, introPlinkSamples, scapes } from '../../samples/samples';
import { Player } from 'tone';
import { getScape, setScene } from '../../utils/soundScapeFunctions';
import { ScapeTrack } from '../../types';
import { disableScroll } from '../../utils/prevent-scroll';
import { useTranslation } from 'react-i18next';
import Nudge from '../../components/Nudges/Nudge';
import Layout from '../../components/Layout/Layout';
import { useSearchParams } from 'react-router-dom';

const plinks = Object.values(introPlinkSamples);

let context: AudioContext | null;
const AudioContext =
  window.AudioContext || // Default
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).webkitAudioContext || // Safari and old versions of Chrome
  false;

if (AudioContext) {
  context = new AudioContext();
} else {
  // Web Audio API is not supported
  // Alert the user
  alert(
    'This site uses functionality not supported by your browser. It will not function as intended. Please upgrade your browser and try again.'
  );
}

export const Home = () => {
  const forceGraphRef = useRef<ForceGraphMethods>(null);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);
  const [nodeToExpand, setNodeToExpand] = useState<Node | null>(null);
  // note: selectedNode is delayed - clickedNode is instant
  const [clickedNode, setClickedNode] = useState<Node | null>(null);

  const [zoomLevel, setZoomLevel] = useState(1);

  const [landingComplete, setLandingComplete] = useState(false);
  const [introComplete, setIntroComplete] = useState(false);
  const [revealGraph, setRevealGraph] = useState(false);
  const [enterFirstNode /* setEnterFirstNode */] = useState(false);
  const [prunedTree, setPrunedTree] = useState<NodeGraph>({
    nodes: [],
    links: [],
  });
  const [conversationLoaded, setConversationLoaded] = useState(false);
  let numScapesLoaded = 0;
  const [scapesLoaded, setScapesLoaded] = useState(false);
  const [fxLoaded, setFxLoaded] = useState(false);

  const { t } = useTranslation('nudges');

  const [searchParams] = useSearchParams();
  const kiosk = searchParams.get('kiosk');

  const conversationTrackRef = useRef<{
    player?: Player;
  }>({});

  const introPlinkTrackRef = useRef<{
    player?: Player;
  }>({});

  const scapeTracksRef = useRef<
    {
      name?: string[];
      player?: Player;
    }[]
  >([]);

  const enterNodeTrackRef = useRef<{
    player?: Player;
  }>({});

  const scapeTrack1 = useRef<ScapeTrack>({});
  const scapeTrack2 = useRef<ScapeTrack>({});

  //add GA if not kiosk
  useEffect(() => {
    if (!kiosk) {
      const googleExists = document.getElementById('google-anlytics');
      if (!googleExists) {
        const googleScript = document.createElement('script');
        googleScript.async = true;
        googleScript.id = 'google-analytics';
        googleScript.src =
          'https://www.googletagmanager.com/gtag/js?id=G-9R1PNKQ1QQ';

        document.head.appendChild(googleScript);
      }
    }
  }, []);

  // First load conversation sound
  useEffect(() => {
    conversationTrackRef.current.player = new Tone.Player(conversation, () => {
      setConversationLoaded(true);
    });
  }, []);

  // Then load all other sounds
  useEffect(() => {
    if (conversationLoaded) {
      introPlinkTrackRef.current.player = new Tone.Player(
        plinks[randBetween(0, plinks.length - 1)]
      );

      scapeTracksRef.current = scapes.map((scape) => {
        return {
          name: scape.name,
          player: new Tone.Player(scape.audio, () => {
            numScapesLoaded++;
            if (numScapesLoaded === scapes.length) {
              setScapesLoaded(true);
            }
          }),
        };
      });

      enterNodeTrackRef.current.player = new Tone.Player(fxNode.enter, () => {
        setFxLoaded(true);
      });
    }
  }, [conversationLoaded]);

  //temp solution to fetch scapes into disk memory
  useEffect(() => {
    if (scapesLoaded) {
      scapeTracksRef.current.forEach((scape) => {
        scape.player?.dispose();
      });
    }
  }, [scapesLoaded]);

  const introPlinkEvent = () => {
    introPlinkTrackRef.current.player &&
      setScene(introPlinkTrackRef.current.player, 'enter', 0.1);

    setTimeout(() => {
      introPlinkTrackRef.current.player &&
        setScene(introPlinkTrackRef.current.player, 'exit');
      introPlinkTrackRef.current.player?.dispose();
    }, 5000);
    setRevealGraph(true);

    conversationTrackRef.current.player &&
      setScene(conversationTrackRef.current.player, 'farback', 0.3);
  };

  const onNodeClick = () => {
    enterNodeTrackRef.current.player &&
      setScene(enterNodeTrackRef.current.player, 'enter', 0);
    setTimeout(() => {
      enterNodeTrackRef.current.player &&
        setScene(enterNodeTrackRef.current.player, 'exit');
    }, 5000);
  };

  const { applyTheme } = useTheme();

  useEffect(() => {
    const handleScapeTrack = () => {
      if (selectedNode?.parentLinkCategory) {
        //return if same scape
        if (
          scapeTrack1.current.player?.state === 'started' &&
          scapeTrack1.current.name?.some(
            (name) => name === selectedNode.parentLinkCategory
          )
        ) {
          //console.log('same scape1', scapeTrack1.current.name);
          return;
        }

        if (
          scapeTrack2.current.player?.state === 'started' &&
          scapeTrack2.current.name?.some(
            (name) => name === selectedNode.parentLinkCategory
          )
        ) {
          //console.log('same scape2', scapeTrack2.current.name);
          return;
        }

        //if not first node
        if (scapeTrack1.current.player?.state === 'started') {
          getScape(selectedNode.parentLinkCategory, scapeTrack2.current);
          if (scapeTrack2.current.player) {
            scapeTrack2.current.player.loop = true;
          }
          setScene(scapeTrack1.current.player, 'exit');
          //console.log('starting track2');
        } else {
          getScape(selectedNode.parentLinkCategory, scapeTrack1.current);
          if (scapeTrack1.current.player) {
            scapeTrack1.current.player.loop = true;
          }
          scapeTrack2.current.player &&
            setScene(scapeTrack2.current.player, 'exit');
          //console.log('starting track1');
        }
      } else {
        //if first node
        selectedNode?.childLinks &&
          getScape(selectedNode?.childLinks[0].cat, scapeTrack1.current);
        conversationTrackRef.current.player &&
          setScene(conversationTrackRef.current.player, 'exit');
        //console.log('first node', { selectedNode });
      }
    };
    if (selectedNode) {
      handleScapeTrack();
    }
  }, [selectedNode]);

  const closeComposingView = (composingComplete: boolean) => {
    if (composingComplete) setNodeToExpand(selectedNode);
    setSelectedNode(null);
  };

  const compressor = useCompressor({
    attack: 0.01,
    knee: 35,
    ratio: 18,
    release: 0.5,
    threshold: -18,
  });

  const onLandingComplete = () => {
    Tone.start();
    Tone.Destination.connect(compressor);
    conversationTrackRef.current.player &&
      setScene(conversationTrackRef.current.player, 'enter', 3, 3, true, true);
    Tone.Transport.start();
    setTimeout(() => {
      setLandingComplete(true);
    }, 1000);
  };

  const onIntroComplete = () => {
    applyTheme('dark');
    setIntroComplete(true);
    introPlinkEvent();
    disableScroll();
  };

  const numComposedNodes = useMemo(
    () => prunedTree.nodes.filter((n) => !n.collapsed).length,
    [prunedTree.nodes]
  );

  return (
    <>
      {!landingComplete ? (
        <Landing
          onLandingComplete={onLandingComplete}
          loading={!conversationLoaded}
          context={context}
        />
      ) : (
        <Layout introComplete={introComplete}>
          {!introComplete || !revealGraph ? (
            <Intro
              onComplete={onIntroComplete}
              loading={!scapesLoaded && !fxLoaded}
            />
          ) : (
            <>
              {selectedNode && (
                <ComposingView
                  selectedNode={selectedNode}
                  onClose={closeComposingView}
                  numComposedNodes={numComposedNodes}
                />
              )}
              <div
                className={`select-none ${
                  selectedNode ? 'pointer-events-none' : ''
                }`}
              >
                <Graph
                  triggerClick={enterFirstNode}
                  forceGraphRef={forceGraphRef}
                  nodeToExpand={nodeToExpand}
                  setNodeToExpand={setNodeToExpand}
                  clickedNode={clickedNode}
                  setClickedNode={setClickedNode}
                  graphData={nodes}
                  setSelectedNode={setSelectedNode}
                  selectedNode={selectedNode}
                  onNodeClick={onNodeClick}
                  prunedTree={prunedTree}
                  setPrunedTree={setPrunedTree}
                  scapeTrack1={scapeTrack1.current}
                  scapeTrack2={scapeTrack2.current}
                  conversationTrack={conversationTrackRef.current}
                  zoomLevel={zoomLevel}
                  setZoomLevel={setZoomLevel}
                />
                <Nudge
                  show={!clickedNode && zoomLevel > 2 && numComposedNodes === 0}
                  line
                  text={t('click')}
                  delay="delay-[0ms]"
                />
                <Nudge
                  show={!clickedNode && zoomLevel > 2 && numComposedNodes === 1}
                  text={t('direct')}
                />
                <Nudge
                  show={!clickedNode && zoomLevel > 2 && numComposedNodes === 5}
                  text={t('pan')}
                />
                <Nudge
                  show={
                    !clickedNode && zoomLevel > 2 && numComposedNodes === 10
                  }
                  text={t('zoom')}
                />
                <Nudge
                  show={
                    !clickedNode && zoomLevel > 2 && numComposedNodes === 13
                  }
                  text={t('drag')}
                />
                <Nudge
                  show={
                    !clickedNode && zoomLevel > 2 && numComposedNodes === 20
                  }
                  text={t('reset')}
                />
              </div>
            </>
          )}
        </Layout>
      )}
    </>
  );
};
