import { useEffect, useState } from 'react';
import Header from './Header/Header';
import { useSearchParams } from 'react-router-dom';

type LayoutProps = {
  children: React.ReactNode;
  introComplete: boolean;
};

const Layout = ({ children, introComplete }: LayoutProps) => {
  const [searchParams] = useSearchParams();
  const kiosk = searchParams.get('kiosk');
  const idle = Number(searchParams.get('idle'));
  let timer: number;
  const [seconds, setSeconds] = useState<number | null>(null);

  const inactivityTime = function () {
    let time: ReturnType<typeof setTimeout>;
    let timerInterval: ReturnType<typeof setInterval>;
    resetTimer();
    document.onmouseup = resetTimer;
    document.onmouseleave = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer;
    document.onscroll = resetTimer;
    document.ontouchstart = resetTimer;
    document.ontouchmove = resetTimer;
    document.ontouchend = resetTimer;

    function reset() {
      window.location.reload();
    }

    function resetTimer() {
      setSeconds(null);
      clearTimeout(time);
      clearInterval(timerInterval);
      if (idle) {
        timer = idle;
        time = setTimeout(reset, idle * 1000);
      } else {
        timer = 60;
        time = setTimeout(reset, 60000);
      }
      timerInterval = setInterval(() => {
        timer--;
        timer < 10 &&
          setTimeout(() => {
            setSeconds(timer);
          }, 100);
      }, 1000);
    }
  };

  useEffect(() => {
    kiosk && inactivityTime();
  }, []);

  return (
    <div className="h-full flex flex-col">
      <Header introComplete={introComplete} seconds={seconds} />
      <main className="flex-grow">{children}</main>
    </div>
  );
};

export default Layout;
