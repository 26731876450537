import { useRef, useState, useEffect } from 'react';

import QuoteDrawer from './QuoteDrawer';
import { Node } from '../../types';
import { Sampler } from 'tone';
import { Part } from 'tone';
import { Loop } from 'tone';
import { Panner } from 'tone';
import { getTranslatedQuote } from '../../utils/quotes';
import { Note } from '../../utils/getMelody';
import { Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import useTheme from '../../theme/useTheme';
import Quote from '../Text/Quote';

import PinkShape from '../../assets/shapes/pink-blur.png';
import RedShape from '../../assets/shapes/red-blur.png';

import {
  getSampler,
  drawPlay,
  recordedMelody,
  recordedMelodySlow,
} from '../../utils/soundInstrumentFunctions';

let firstChar = false;

type ComposingViewProps = {
  selectedNode: Node;
  onClose: (composingComplete: boolean) => void;
  numComposedNodes: number;
};

const ComposingView = ({
  selectedNode,
  onClose,
  numComposedNodes,
}: ComposingViewProps) => {
  const composingViewRef = useRef<HTMLDivElement>(null);

  const [composingStarted, setComposingStarted] = useState(false);
  const [composingComplete, setComposingComplete] = useState(false);
  const [composingPaused, setComposingPaused] = useState(false);

  const [showDelayedNudge, setShowDelayedNudge] = useState(false);
  const showDelayedNudgeRef = useRef<NodeJS.Timeout | null>(null);

  const [isClosing, setIsClosing] = useState(false);
  const { t, i18n } = useTranslation('composing');
  const containerRef = useRef<HTMLDivElement>(null);

  const samplerRef = useRef<Sampler | null>(null);
  const samplerLoopRef = useRef<Sampler | null>(null);
  const isClosingRef = useRef(false);

  const [isAndroid] = useState(/Android/i.test(navigator.userAgent));

  const getRandomBlobXPosition = () => {
    return [
      '-translate-x-[50%] md:-translate-x-[50%] rotate-45',
      '-translate-x-[45%] md:-translate-x-[30%] scale-x-[1.5]',
      '-translate-x-[40%] md:-translate-x-[20%] rotate-12',
      '-translate-x-[30%] md:translate-x-[0%]',
      '-translate-x-[20%] md:translate-x-[20%] rotate-90 scale-x-[1.5]',
      '-translate-x-[10%] md:translate-x-[30%]',
      '-translate-x-[5%] md:translate-x-[40%] rotate-180',
      '-translate-x-[0%] md:translate-x-[50%] scale-y-[2.1]',
    ][Math.floor(Math.random() * 8)];
  };

  const getRandomBlobYPosition = () => {
    return [
      '-translate-y-[50%] scale-y-[2.1]',
      '-translate-y-[30%] rotate-180',
      '-translate-y-[10%] scale-x-[1.5]',
      '-translate-y-[0%] rotate-45',
      'translate-y-[10%] scale-y-[2.1]',
      'translate-y-[20%] rotate-12 scale-x-[1.5]',
      'translate-y-[30%] scale-x-[1.5]',
      'translate-y-[50%] rotate-90',
    ][Math.floor(Math.random() * 8)];
  };

  const [redBlobPosition] = useState({
    x: getRandomBlobXPosition(),
    y: getRandomBlobYPosition(),
  });

  const [pinkBlobPosition] = useState({
    x: getRandomBlobXPosition(),
    y: getRandomBlobYPosition(),
  });

  useEffect(() => {
    if (!selectedNode.collapsed) {
      return;
    }
    const { sampler, samplerLoop } = getSampler(
      selectedNode.parentLinkCategory ?? selectedNode.childLinks[0].cat,
      true
    );
    samplerRef.current = sampler;
    samplerLoopRef.current = samplerLoop;
  }, []);

  const { applyTheme } = useTheme();

  const quote = getTranslatedQuote(selectedNode, i18n.language);

  const setupInteractToCancel = () => {
    setTimeout(() => {
      if (composingViewRef.current) {
        composingViewRef.current.addEventListener('mouseup', handleCloseView);
        composingViewRef.current.addEventListener(
          'touchstart',
          handleCloseView
        );
      }
    }, 2000);
  };

  useEffect(() => {
    if (selectedNode.collapsed) {
      setTimeout(() => applyTheme('white'), 1000);
    } else {
      setupInteractToCancel();
    }

    return () => {
      if (composingViewRef.current) {
        composingViewRef.current.removeEventListener(
          'mouseup',
          handleCloseView
        );
      }
    };
  }, []);

  useEffect(() => {
    if (composingComplete) {
      setupInteractToCancel();
    }
  }, [composingComplete]);

  const pannerRef = useRef<Panner>(new Panner().toDestination());

  const playRecordedMelody = (melody: [number, Note | null][]) => {
    const part = new Part((time, note) => {
      note?.note &&
        !selectedNode.sound?.sampler?.disposed &&
        selectedNode.sound?.sampler?.triggerAttackRelease(
          `${note.note}${note.octave}`,
          '1n',
          time
        );
    }, melody);

    part.start();
  };

  const playLoopedMelody = () => {
    const timing = ['2m', '2.5', '2m', '3m'][Math.floor(Math.random() * 4)];

    //('loop timing', timing);
    const part = new Part((time, note) => {
      note?.note &&
        !selectedNode.sound?.samplerLoop?.disposed &&
        selectedNode.sound?.samplerLoop?.triggerAttackRelease(
          `${note.note}${note.octave}`,
          '1n',
          time
        );
    }, selectedNode.sound && selectedNode.sound.recordedMelodySlow);

    if (selectedNode.sound) {
      selectedNode.sound.part = part;
    }
    const loop = new Loop(() => {
      //console.log('looping');
      selectedNode.sound && selectedNode.sound.part?.stop();
      selectedNode.sound && selectedNode.sound.part?.start();
    }, timing);

    if (selectedNode.sound) {
      selectedNode.sound.loop = loop;
      selectedNode.sound.loop.start();
    }
  };

  const onDrawingPaused = () => setComposingPaused(true);

  const onDrawingStart = () => {
    setComposingStarted(true);
    setShowDelayedNudge(false);
    setComposingPaused(false);
  };

  useEffect(() => {
    if (
      !selectedNode.root &&
      numComposedNodes < 4 &&
      (!selectedNode.composed || composingComplete)
    ) {
      showDelayedNudgeRef.current = setTimeout(() => {
        setShowDelayedNudge(true);
      }, 2000);
    }
  }, []);

  const onComposingComplete = () => {
    selectedNode.composed = true;
    selectedNode.sound = {
      recordedMelody: recordedMelody,
      recordedMelodySlow: recordedMelodySlow,
      sampler: samplerRef.current,
      samplerLoop: samplerLoopRef.current,
      panner: pannerRef.current,
      isConnected: true,
    };

    if (selectedNode.sound) {
      selectedNode.sound.sampler?.toDestination();
      selectedNode.sound.samplerLoop?.toDestination();
    }

    setComposingComplete(true);

    setTimeout(() => {
      selectedNode.sound &&
        playRecordedMelody(selectedNode.sound?.recordedMelody);
    }, 2000);
  };

  useEffect(() => {
    firstChar = true;
    if (selectedNode.composed && selectedNode.sound) {
      const { sampler } = getSampler(
        selectedNode.parentLinkCategory ?? selectedNode.childLinks[0].cat
      );
      selectedNode.sound.sampler = sampler;
      selectedNode.sound?.sampler?.toDestination();
      setTimeout(() => {
        selectedNode.sound?.recordedMelody &&
          playRecordedMelody(selectedNode.sound?.recordedMelody);
      }, 500);
    }
  }, [selectedNode]);

  const closeView = () => {
    onClose(true);
  };

  const handleCloseView = () => {
    if (!isClosingRef.current) {
      handleLoop();
      setTimeout(closeView, 1000);
    }
    isClosingRef.current = true;
    setIsClosing(true);
  };

  const handleLoop = () => {
    setTimeout(() => {
      selectedNode.sound?.sampler?.volume.rampTo(-50, 0.5, '+0');
      setTimeout(() => {
        selectedNode.sound?.sampler?.dispose();
      }, 600);
    }, 3000);

    if (composingComplete) {
      setTimeout(() => {
        playLoopedMelody();
      }, 2000);
    }
  };

  return (
    <Transition
      className={`opacity-100 transition-opacity duration-1000 absolute h-full ${
        isAndroid ? 'min-h-screen' : ''
      } flex flex-col justify-between z-10 w-screen`}
      show={!isClosing}
      appear
      ref={composingViewRef}
      enterFrom="opacity-100"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Transition show={composingComplete || !selectedNode.collapsed} appear>
        <Transition.Child
          className={`absolute opacity-0 ${
            !selectedNode.collapsed
              ? 'transition-opacity duration-1000'
              : 'transition-none'
          } absolute pointer-events-none flex flex-col overflow-hidden w-full h-full`}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-1000"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 w-screen h-full bg-black opacity-[0.45]"></div>
          <img
            className="red absolute scale-x-[4] scale-y-[5] translate-y-[15%]  md:scale-x-[3] md:scale-y-[2] md:-translate-y-[20%]"
            src={RedShape}
          />
          <img
            className="pink absolute scale-x-[4] scale-y-[3] translate-x-[80%] translate-y-[80%] md:scale-x-[2] md:rotate-[67deg] md:translate-y-[20%] md:scale-y-[2.5]"
            src={PinkShape}
          />
        </Transition.Child>

        <Transition.Child
          as={'figure'}
          className={`absolute opacity-0 md:ml-[15%] pt-36 ${
            !selectedNode.collapsed
              ? 'transition-opacity duration-1000'
              : 'transition-none'
          }  max-w-lg z-10 font-serif left-8 right-7`}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Quote selectedNode={selectedNode} quote={quote} />
        </Transition.Child>

        <Transition.Child
          className="left-1/2 -translate-x-1/2 w-fit opacity-0 delay-[4000ms] transition-opacity duration-1000 absolute bottom-0 pb-[48px] z-20"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {t('continue_exploring')}
        </Transition.Child>
      </Transition>

      <Transition
        show={
          !showDelayedNudge &&
          !(composingComplete || selectedNode.composed) &&
          composingPaused
        }
        className="self-center pointer-events-none absolute bottom-0 pb-[48px] z-30 opacity-0 transition-opacity duration-1000"
        enter="delay-[750ms]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-[500ms]"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        appear
      >
        <p className="pointer-events-none">{t('continue_composing')}</p>
      </Transition>

      <Transition
        show={
          !composingStarted &&
          (showDelayedNudge ||
            (selectedNode.root &&
              !(composingComplete || selectedNode.composed) &&
              !composingPaused))
        }
        className="self-center pointer-events-none absolute bottom-0 pb-[48px] z-30 opacity-0 transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        appear={selectedNode.root || showDelayedNudge}
      >
        <p className="pointer-events-none">{t('compose')}</p>
      </Transition>

      <Transition
        show={!composingComplete && selectedNode.collapsed}
        appear
        className="transition-all duration-[2000ms] z-10 absolute flex flex-col overflow-hidden w-full h-full"
        enter="circle-clip-0"
        leave="circle-clip-1"
      >
        <div
          className={`absolute pointer-events-none bg-white w-[100vmax] h-[100vmax]`}
        >
          <div className={`absolute pointer-events-none w-full h-full`}>
            <img
              alt=""
              className={`absolute opacity-90 z-10 animate-slowPulse ${redBlobPosition.y} ${redBlobPosition.x}`}
              src={RedShape}
            />

            <img
              alt=""
              className={`absolute opacity-90 animate-slowPulseDelayed ${pinkBlobPosition.y} ${pinkBlobPosition.x}`}
              src={PinkShape}
            />
          </div>
        </div>
        {selectedNode.collapsed && (
          <div
            className="w-full h-full overflow-hidden z-10"
            ref={containerRef}
          >
            <QuoteDrawer
              quote={quote}
              onComposingComplete={onComposingComplete}
              onDrawingStart={onDrawingStart}
              onDrawingPaused={onDrawingPaused}
              containerRef={containerRef}
              onWordDraw={({
                speed,
                x,
                y,
                prevX,
                prevY,
                word,
                seconds,
                wentDown,
                wentRight,
                isLast,
              }) => {
                drawPlay({
                  speed,
                  x,
                  y,
                  prevX,
                  prevY,
                  selectedNode,
                  seconds,
                  wentDown,
                  wentRight,
                  word,
                  isLast,
                  isFirst: firstChar,
                });

                firstChar = false;
              }}
            />
          </div>
        )}
      </Transition>
    </Transition>
  );
};

export default ComposingView;
