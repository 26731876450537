import { createContext, useState } from 'react';
import { Theme } from '../types';

type ThemeProviderProps = {
  children: React.ReactNode;
};

const themeTextColor = {
  black: '--white',
  dark: '--white',
  white: '--black',
  grey: '--black',
  yellow: '--black',
  gold: '--black',
  pink: '--black',
  red: '--black',
  burgundy: '--white',
};

interface ThemeContextInterface {
  activeTheme: Theme;
  applyTheme: (theme: Theme, instant?: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const ThemeContext = createContext<ThemeContextInterface>({
  activeTheme: 'pink',
  applyTheme: noop,
});

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [activeTheme, setActiveTheme] = useState<Theme>('pink');

  const applyTheme = (theme: Theme, instant = false) => {
    let previousTransitionDuration = '';

    if (instant) {
      const bodyStyle = getComputedStyle(document.body);
      previousTransitionDuration = bodyStyle.transitionDuration;
      document.body.style.transitionDuration = '0s';
    }

    const root = document.documentElement;
    const style = getComputedStyle(root);

    const bgColor = style.getPropertyValue(`--${theme}`);
    root.style.setProperty('--base-background', bgColor);

    const textColor = style.getPropertyValue(themeTextColor[theme]);
    root.style.setProperty('--text-base', textColor);
    setActiveTheme(theme);

    if (instant) {
      setTimeout(() => {
        document.body.style.transitionDuration = previousTransitionDuration;
      }, 10);
    }
  };

  const value: ThemeContextInterface = { activeTheme, applyTheme };

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;
