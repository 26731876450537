import { useTranslation } from 'react-i18next';

import { ReactComponent as ExternalLinkIcon } from '../../assets/icons/external-link.svg';
import LanguagePicker from './LanguagePicker';

const Menu = () => {
  const { t } = useTranslation('menu');

  const menuItems = [
    {
      label: t('exhibition'),
      link: 'https://evigtliv.nobelprizemuseum.se',
      style: '',
    },
    {
      label: t('visit_us'),
      link: 'https://liljevalchs.se/besok',
      style: '',
    },
    {
      label: t('nobel_prize_museum'),
      link: 'https://nobelprizemuseum.se',
      style: 'w-[202px] xxs:w-full',
    },
  ];

  return (
    <>
      <LanguagePicker />
      <div className="absolute -z-10 h-screen top-0 pb-16 flex flex-col items-center justify-center w-full bg-base gap-4 md:gap-8">
        {menuItems.map((item, index) => {
          return (
            <div key={`${index}${item.link}`}>
              <a
                className="flex text-center items-center gap-2"
                rel="noopener noreferrer"
                target="_blank"
                href={item.link}
              >
                <span className={item.style}>{item.label}</span>
                <div>
                  <ExternalLinkIcon />
                </div>
              </a>
            </div>
          );
        })}
      </div>

      <div className="absolute pointer-events-none inset-0 h-screen flex justify-center items-end">
        <div className="mb-[20vh] text-center px-8">{t('description')}</div>
      </div>
    </>
  );
};

export default Menu;
