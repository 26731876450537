import { useTranslation } from 'react-i18next';

const LanguagePicker = () => {
  const { i18n } = useTranslation('menu');

  const switchLanguage = (lang: string) => {
    i18n.changeLanguage(lang, (err) => {
      if (err) return console.log('Changing language failed:', err);
    });
  };
  return (
    <div className="w-full flex justify-end px-5 ">
      <button
        className={`h-[60px] min-w-[60px] px-4`}
        onClick={() => switchLanguage('sv')}
      >
        <div
          className={`${
            !i18n.language.includes('sv') ? 'opacity-50' : 'border-b leading-10'
          }`}
        >
          Svenska
        </div>
      </button>

      <button
        className={`h-[60px] min-w-[60px] px-4 -mr-4`}
        onClick={() => switchLanguage('en')}
      >
        <div
          className={`${
            i18n.language.includes('sv') ? 'opacity-50' : 'border-b leading-10'
          } `}
        >
          English
        </div>
      </button>
    </div>
  );
};

export default LanguagePicker;
