import * as Tone from 'tone';
import { Player } from 'tone';

import {
  relativityScape,
  compassionScape,
  fearScape,
  existentialismScape,
  knowledgeScape,
  hopeScape,
  spiritualityScape,
  timeScape,
} from '../samples/samples';
import { ScapeTrack } from '../types';

const rampTime = () => (Tone.Transport.state === 'started' ? '2m' : 3);

const volumeLevel = {
  lowest: -24,
  low: -14,
  normal: 0,
};

const lowpassFilter = new Tone.Filter({
  type: 'lowpass',
  frequency: 20000,
  rolloff: -96,
});

const highpassFilter = new Tone.Filter({
  type: 'highpass',
  frequency: 0,
  rolloff: -96,
});

const lowpass = (time: number | string = rampTime()) => {
  highpassFilter.frequency.linearRampTo(0, time);
  lowpassFilter.frequency.linearRampTo(600, time);
};

const highpass = (time: number | string = rampTime()) => {
  lowpassFilter.frequency.linearRampTo(2000, time);
  highpassFilter.frequency.linearRampTo(600, time);
};

const allpass = (time: number | string = rampTime()) => {
  lowpassFilter.frequency.linearRampTo(2000, time);
  highpassFilter.frequency.linearRampTo(0, time);
};

const setVolume = (
  player: Tone.Player,
  value: 'normal' | 'low' | 'lowest' = 'normal',
  time: number | string = rampTime()
) => {
  const vol = volumeLevel[value];
  player.volume.rampTo(vol, time);
};

const start = (
  player: Tone.Player,
  fadeInTime: number | string = rampTime(),
  fadeOutTime: number | string = rampTime(),
  loop = false
) => {
  if (player.state === 'started' || !player?.loaded) return;
  if (player) {
    player.fadeIn = fadeInTime;
    player.fadeOut = fadeOutTime;
    player.loop = loop;
    player.start();
  }
};
const stop = (player: Tone.Player) => {
  if (player?.state === 'stopped') return;
  if (player) {
    player.stop();
  }
};

export const setScene = (
  player: Tone.Player,
  type: string,
  time: number | string = rampTime(),
  fadeOutTime: number | string = rampTime(),
  loop = false,
  filter = false
) => {
  if (filter) {
    player.chain(lowpassFilter, highpassFilter, Tone.Destination);
  } else {
    player.toDestination();
  }

  switch (type) {
    case 'farback': {
      if (filter) {
        lowpass(time);
      }

      setVolume(player, 'lowest', time);
      break;
    }
    case 'background': {
      lowpass(time);
      setVolume(player, 'low', time);
      break;
    }
    case 'whisper': {
      highpass(time);
      setVolume(player, 'low', time);
      break;
    }
    case 'foreground': {
      if (filter) {
        allpass(time);
      }

      setVolume(player, 'normal', time);
      break;
    }
    case 'enter': {
      if (player.volume.value !== 0) {
        setVolume(player, 'low', 0.2);
      }
      start(player, time, fadeOutTime, loop);
      break;
    }
    case 'exit': {
      stop(player);
      break;
    }
  }
};

export const getScape = (scapeType: string, scapeTrack: ScapeTrack) => {
  if (scapeTrack.player?.state === 'started') {
    return;
  }
  switch (scapeType) {
    case 'existentialism':
    case 'challenge':
      scapeTrack.player = new Player(existentialismScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['existentialism', 'challenge'];
      break;

    case 'fear':
      scapeTrack.player = new Player(fearScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['fear'];
      break;

    case 'spirituality':
    case 'life':
    case 'culture':
      scapeTrack.player = new Player(spiritualityScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['spirituality', 'life', 'culture'];
      break;

    case 'knowledge':
    case 'progress':
      scapeTrack.player = new Player(knowledgeScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['knowledge', 'progress'];
      break;

    case 'hope':
    case 'love':
      scapeTrack.player = new Player(hopeScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['hope', 'love'];
      break;

    case 'compassion':
    case 'equality':
      scapeTrack.player = new Player(compassionScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['compassion', 'equality'];
      break;

    case 'time':
    case 'perspective':
      scapeTrack.player = new Player(timeScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['time', 'perspective'];
      break;

    case 'relativity':
    case 'imagination':
      scapeTrack.player = new Player(relativityScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['relativity', 'imagination'];
      break;

    default:
      scapeTrack.player = new Player(compassionScape.audio, () => {
        scapeTrack.player && setScene(scapeTrack.player, 'enter', 3, 5, true);
      });
      scapeTrack.name = ['compassion', 'equality'];
  }
};
