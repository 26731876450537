import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTheme from '../../../theme/useTheme';
import { Theme } from '../../../types';
import Hamburger from '../../Menu/Hamburger';
import Menu from '../../Menu/Menu';
import * as Tone from 'tone';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as LifeEternalLogoSV } from '../../../assets/wordmarks/life-eternal_sv.svg';
import { ReactComponent as LifeEternalLogoEN } from '../../../assets/wordmarks/life-eternal_en.svg';
import { ReactComponent as VolumeOnIcon } from '../../../assets/icons/volume-on.svg';
import { ReactComponent as VolumeOffIcon } from '../../../assets/icons/volume-off.svg';
import { disableScroll, enableScroll } from '../../../utils/prevent-scroll';
import LanguagePicker from '../../Menu/LanguagePicker';
import ResetButton from '../../Menu/ResetButton';

const Header = ({
  introComplete,
  seconds,
}: {
  introComplete: boolean;
  seconds: number | null;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const { i18n } = useTranslation('header');
  const headerRef = useRef<HTMLElement>(null);
  const { applyTheme, activeTheme } = useTheme();
  const [searchParams] = useSearchParams();

  const kiosk = searchParams.get('kiosk');

  const previousThemeRef = useRef<Theme>();

  useEffect(() => {
    Tone.Destination.mute = !soundEnabled;
    if (soundEnabled) {
      Tone.Destination.volume.value = -10;
    }
  }, [soundEnabled]);

  const toggleMenu = () => {
    if (!showMenu) {
      applyTheme('white', true);
      previousThemeRef.current = activeTheme;

      // 1. This, and 2. below, are classical last-minute fulhacks to enable
      // scolling _somewhere_ in the app to e.g. access the navbar on Android
      // or do a pull to refresh. The logic is inverse on the intro page to
      // avoid scrolling in the background of the menu.
      if (introComplete) {
        enableScroll();
      } else {
        disableScroll();
      }
    } else {
      applyTheme(previousThemeRef.current!, true);

      // 2.
      if (introComplete) {
        disableScroll();
      } else {
        enableScroll();
      }
    }
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (headerRef.current) {
      const headerBottom = headerRef.current.getBoundingClientRect().bottom;
      const root = document.documentElement;
      root.style.setProperty('--header-height', `${headerBottom}px`);
    }
  }, [headerRef.current]);

  return (
    <header className="fixed h-[60px] w-full z-20" ref={headerRef}>
      <div className="flex flex-col h-full px-5 justify-between">
        <div className="flex justify-between">
          <h3 className="normal-case mt-auto mb-[14px]">
            {i18n.language.includes('sv') ? (
              <LifeEternalLogoSV />
            ) : (
              <LifeEternalLogoEN />
            )}
          </h3>
          <div className="flex">
            {kiosk ? (
              <div className="flex">
                <LanguagePicker />
                <ResetButton seconds={seconds} />
              </div>
            ) : (
              <>
                <button
                  aria-label="Toggle sound"
                  className="flex justify-center self-end items-center w-[60px] h-[60px] py-[15px]"
                  onClick={() => setSoundEnabled(!soundEnabled)}
                >
                  {soundEnabled ? <VolumeOnIcon /> : <VolumeOffIcon />}
                </button>
                <button
                  aria-label="Toggle menu"
                  className="flex justify-center -mr-[18px] w-[60px] h-[60px] py-[15px]"
                  onClick={toggleMenu}
                >
                  <Hamburger isOpen={showMenu} />
                </button>
              </>
            )}
          </div>
        </div>
        <div className="border-t w-full transition-all duration-1000 border-text-base"></div>
      </div>
      {showMenu && <Menu />}
      <div
        className={`absolute top-0 bg-base ${
          !showMenu ? 'transition-colors duration-1000' : ''
        } w-full h-full -z-20 opacity-header`}
      ></div>
    </header>
  );
};

export default Header;
