/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Textfit } from 'react-textfit';
import Laureate from '../Quote/Laureate';
import PopCulture from '../Quote/PopCulture';

// eslint-disable-next-line react/prop-types
export default function Quote({ quote, selectedNode }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [fontSize, setFontSize] = useState(35);
  const { t } = useTranslation('composing');

  const onResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className="grid grid-rows-2 auto-cols-quote">
      <div style={{ fontSize: fontSize, lineHeight: 1.2 }}>
        {' '}
        {t('quotation_mark_start')}&nbsp;
      </div>

      <Textfit
        className="max-h-[40vh] min-h-[20vh] md:max-h-[55vh] md:w-[60vw] lg:w-[50vw]"
        onReady={setFontSize}
        mode="multi"
        min={20}
        max={width < 768 ? 35 : 83}
        style={{
          lineHeight: 1.2,
        }}
      >
        <div className="flex">
          <div>
            {quote}&nbsp;{t('quotation_mark_end')}
          </div>
        </div>
      </Textfit>

      <figcaption
        className={`row-start-2 col-start-2 text-white font-sans uppercase mt-4`}
      >
        {selectedNode.laureate_index ? (
          <Laureate node={selectedNode} />
        ) : (
          <PopCulture node={selectedNode} />
        )}
      </figcaption>
    </div>
  );
}
