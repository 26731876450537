const ResetButton = ({ seconds }: { seconds: number | null }) => {
  const onClick = () => {
    window.location.reload();
  };
  return (
    <button
      onClick={onClick}
      className="text-[22px] w-14 flex items-center justify-center"
    >
      <div className="w-full">
        {seconds ? seconds : <div className="rotate-45">&#8635;</div>}
      </div>
    </button>
  );
};

export default ResetButton;
