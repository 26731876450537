import { Compressor, Sampler } from 'tone';
import { Node } from '../types/index';
import { getNextNote, Note, scales } from '../utils/getMelody';
import { randBetween } from './math';
import {
  compassion,
  hope,
  existentialism,
  fear,
  spirituality,
  knowledge,
  time,
  relativity,
} from '../samples/samples';

const rootScale = scales[0];
let scale = rootScale;
let lastDrewDown: boolean;
let skippedNotes: number[] = [];

const updateScale = () => {
  const removeCount = randBetween(1, 4);
  skippedNotes = [];

  new Array(removeCount).fill(null).forEach(() => {
    const removeStep = randBetween(1, 7, skippedNotes);
    skippedNotes.push(removeStep);
  });

  scale = {
    name: 'custom',
    notes: rootScale.notes.filter(
      (note) => skippedNotes.indexOf(note.function) === -1
    ),
    functions: rootScale.notes
      .filter(
        (note, index, self) =>
          self.findIndex((n) => n.function === note.function) === index &&
          skippedNotes.indexOf(note.function) === -1
      )
      .map((func) => func.function),
  };
};

let note: Note = {
  note: 'C',
  octave: 3,
  function: 1,
};
let prevInterval: number;
let prevNote: Note;

const compressor = new Compressor({
  attack: 0.01,
  knee: 35,
  ratio: 18,
  release: 0.5,
  threshold: -2,
}).toDestination();

export let recordedMelody: Array<[number, Note | null]> = [];
export let recordedMelodySlow: Array<[number, Note | null]> = [];

let sampler: Sampler;
let samplerLoop: Sampler;

export const getSampler = (instrumentType: string, bothSamplers?: boolean) => {
  updateScale();
  recordedMelody = [];
  recordedMelodySlow = [];
  switch (instrumentType) {
    case 'existentialism':
    case 'challenge':
      if (bothSamplers) {
        sampler = new Sampler({ ...existentialism });
        samplerLoop = new Sampler({ ...existentialism });
      } else {
        sampler = new Sampler({ ...existentialism });
      }

      break;
    case 'fear':
      if (bothSamplers) {
        sampler = new Sampler({ ...fear });
        samplerLoop = new Sampler({ ...fear });
      } else {
        sampler = new Sampler({ ...fear });
      }
      break;

    case 'spirituality':
    case 'life':
    case 'culture':
      if (bothSamplers) {
        sampler = new Sampler({ ...spirituality });
        samplerLoop = new Sampler({ ...spirituality });
      } else {
        sampler = new Sampler({ ...spirituality });
      }
      break;

    case 'knowledge':
    case 'progress':
      if (bothSamplers) {
        sampler = new Sampler({ ...knowledge });
        samplerLoop = new Sampler({ ...knowledge });
      } else {
        sampler = new Sampler({ ...knowledge });
      }
      break;

    case 'hope':
    case 'love':
      if (bothSamplers) {
        sampler = new Sampler({ ...hope });
        samplerLoop = new Sampler({ ...hope });
      } else {
        sampler = new Sampler({ ...hope });
      }
      break;

    case 'compassion':
    case 'equality':
      if (bothSamplers) {
        sampler = new Sampler({ ...compassion });
        samplerLoop = new Sampler({ ...compassion });
      } else {
        sampler = new Sampler({ ...compassion });
      }
      break;

    case 'time':
    case 'perspective':
      if (bothSamplers) {
        sampler = new Sampler({ ...time });
        samplerLoop = new Sampler({ ...time });
      } else {
        sampler = new Sampler({ ...time });
      }
      break;

    case 'relativity':
    case 'imagination':
      if (bothSamplers) {
        sampler = new Sampler({ ...relativity });
        samplerLoop = new Sampler({ ...relativity });
      } else {
        sampler = new Sampler({ ...relativity });
      }
      break;

    default:
      if (bothSamplers) {
        sampler = new Sampler({ ...compassion });
        samplerLoop = new Sampler({ ...compassion });
      } else {
        sampler = new Sampler({ ...compassion });
      }
  }

  if (sampler) {
    sampler.volume.value = -10;
    sampler.connect(compressor);
  }
  if (samplerLoop) {
    samplerLoop.volume.value = -50;
    samplerLoop?.connect(compressor);
  }

  return { sampler, samplerLoop };
};

interface drawPlayProps {
  speed: number;
  x: number;
  y: number;
  prevX: number;
  prevY: number;
  selectedNode: Node;
  seconds: number;
  wentDown?: boolean;
  wentRight?: boolean;
  word?: string;
  isFirst?: boolean;
  isLast?: boolean;
}

export const drawPlay = ({ speed, x, y, seconds, wentDown }: drawPlayProps) => {
  if (lastDrewDown !== undefined) {
    if (lastDrewDown !== wentDown) {
      updateScale();
    }
  }
  prevNote = { ...note };
  const yIndex = Math.round(scale.notes.length * y);
  const scaleLen = scale.notes.length;
  const noteIndex = scaleLen - yIndex;
  note = scale.notes[noteIndex];

  const sameNote = prevNote.note === note.note;

  if (wentDown !== undefined) lastDrewDown = wentDown;

  return sameNote
    ? getNextNote({ prevNote, interval: 1, down: wentDown, scale })
    : playNote(speed, x, seconds);
};

const playNote = (speed: number, x: number, seconds: number) => {
  if (!sampler) {
    //console.log('Sampler not initialized', sampler);
    return {
      note,
      prevNote,
      prevInterval,
    };
  }

  recordedMelody.push([seconds, note]);
  recordedMelodySlow.push([seconds * 1.6, note]);
  const noteStr = note?.note + note?.octave;

  outputNote(noteStr);

  return {
    note,
    prevNote,
    prevInterval,
  };
};

const outputNote = (note: string) => {
  note && sampler?.triggerAttack([note]);
};
