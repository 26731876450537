/* eslint-disable no-empty */
const preventDefault = (e) => {
  e.preventDefault();
};

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener(
    'test',
    null,
    Object.defineProperty({}, 'passive', {
      // eslint-disable-next-line getter-return
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

let passive = supportsPassive ? { passive: false } : false;

// call this to Disable
export const disableScroll = () => {
  window.addEventListener('touchmove', preventDefault, passive);
};

// call this to Enable
export const enableScroll = () => {
  window.removeEventListener('touchmove', preventDefault, passive);
};
