import { useTranslation } from 'react-i18next';
import { Node } from '../../types';

const PopCulture = ({ node }: { node: Node }) => {
  const { i18n, t } = useTranslation('medium');

  const source = i18n.language.includes('sv') ? node.source_sv : node.source_en;
  const medium = node.medium ? t(node.medium) : null;

  const secondLine = [];
  if (node.person) secondLine.push(node.person);
  if (medium) secondLine.push(medium);

  return (
    <div>
      <div className="font-sans uppercase">{source}</div>
      <div className="font-sans normal-case">
        {secondLine.join(', ')}
        {node.year ? ` – ${node.year}` : ''}
      </div>
    </div>
  );
};

export default PopCulture;
