import composeEnter from './fx/fx--compose-enter.mp3';
import composeExit from './fx/fx--compose-exit.mp3';

import plink1 from './fx/fx--plink-variant-1.mp3';
import plink2 from './fx/fx--plink-variant-2.mp3';
import plink3 from './fx/fx--plink-variant-3.mp3';
import plink4 from './fx/fx--plink-variant-4.mp3';
import plink5 from './fx/fx--plink-variant-5.mp3';
import plink6 from './fx/fx--plink-variant-6.mp3';

import existentialismC0 from './existentialism/C0.mp3';
import existentialismC1 from './existentialism/C1.mp3';
import existentialismC2 from './existentialism/C2.mp3';
import existentialismC3 from './existentialism/C3.mp3';
import existentialismC4 from './existentialism/C4.mp3';
import existentialismC5 from './existentialism/C5.mp3';
import existentialismC6 from './existentialism/C6.mp3';
import existentialismC7 from './existentialism/C7.mp3';

import fearC0 from './fear/C0.mp3';
import fearC1 from './fear/C1.mp3';
import fearC2 from './fear/C2.mp3';
import fearC3 from './fear/C3.mp3';
import fearC4 from './fear/C4.mp3';
import fearC5 from './fear/C5.mp3';
import fearC6 from './fear/C6.mp3';
import fearC7 from './fear/C7.mp3';

import hopeC0 from './hope/C0.mp3';
import hopeC1 from './hope/C1.mp3';
import hopeC2 from './hope/C2.mp3';
import hopeC3 from './hope/C3.mp3';
import hopeC4 from './hope/C4.mp3';
import hopeC5 from './hope/C5.mp3';
import hopeC6 from './hope/C6.mp3';
import hopeC7 from './hope/C7.mp3';

import knowledgeC0 from './knowledge/C0.mp3';
import knowledgeC1 from './knowledge/C1.mp3';
import knowledgeC2 from './knowledge/C2.mp3';
import knowledgeC3 from './knowledge/C3.mp3';
import knowledgeC4 from './knowledge/C4.mp3';
import knowledgeC5 from './knowledge/C5.mp3';
import knowledgeC6 from './knowledge/C6.mp3';
import knowledgeC7 from './knowledge/C7.mp3';

import spiritualityC0 from './spirituality/C0.mp3';
import spiritualityC1 from './spirituality/C1.mp3';
import spiritualityC2 from './spirituality/C2.mp3';
import spiritualityC3 from './spirituality/C3.mp3';
import spiritualityC4 from './spirituality/C4.mp3';
import spiritualityC5 from './spirituality/C5.mp3';
import spiritualityC6 from './spirituality/C6.mp3';
import spiritualityC7 from './spirituality/C7.mp3';

import compassionC0 from './compassion/C0.mp3';
import compassionC1 from './compassion/C1.mp3';
import compassionC2 from './compassion/C2.mp3';
import compassionC3 from './compassion/C3.mp3';
import compassionC4 from './compassion/C4.mp3';
import compassionC5 from './compassion/C5.mp3';
import compassionC6 from './compassion/C6.mp3';
import compassionC7 from './compassion/C7.mp3';

import relativityC0 from './relativity/C0.mp3';
import relativityC1 from './relativity/C1.mp3';
import relativityC2 from './relativity/C2.mp3';
import relativityC3 from './relativity/C3.mp3';
import relativityC4 from './relativity/C4.mp3';
import relativityC5 from './relativity/C5.mp3';
import relativityC6 from './relativity/C6.mp3';
import relativityC7 from './relativity/C7.mp3';

import timeC0 from './time/C0.mp3';
import timeC1 from './time/C1.mp3';
import timeC2 from './time/C2.mp3';
import timeC3 from './time/C3.mp3';
import timeC4 from './time/C4.mp3';
import timeC5 from './time/C5.mp3';
import timeC6 from './time/C6.mp3';
import timeC7 from './time/C7.mp3';

import scapeCompassion from './scape/scape--compassion.mp3';
import scapeExistentialism from './scape/scape--existentialism.mp3';
import scapeFear from './scape/scape--fear.mp3';
import scapeHope from './scape/scape--hope.mp3';
import scapeKnowledge from './scape/scape--knowledge.mp3';
import scapeRelativity from './scape/scape--relativity.mp3';
import scapeSpirituality from './scape/scape--spirituality.mp3';
import scapeTime from './scape/scape--time.mp3';

export const introPlinkSamples = {
  plink1,
  plink2,
  plink3,
  plink4,
  plink5,
  plink6,
};

export const fxNode = {
  enter: composeEnter,
  exit: composeExit,
};

export const existentialism = {
  C0: existentialismC0,
  C1: existentialismC1,
  C2: existentialismC2,
  C3: existentialismC3,
  C4: existentialismC4,
  C5: existentialismC5,
  C6: existentialismC6,
  C7: existentialismC7,
};

export const fear = {
  C0: fearC0,
  C1: fearC1,
  C2: fearC2,
  C3: fearC3,
  C4: fearC4,
  C5: fearC5,
  C6: fearC6,
  C7: fearC7,
};

export const hope = {
  C0: hopeC0,
  C1: hopeC1,
  C2: hopeC2,
  C3: hopeC3,
  C4: hopeC4,
  C5: hopeC5,
  C6: hopeC6,
  C7: hopeC7,
};

export const knowledge = {
  C0: knowledgeC0,
  C1: knowledgeC1,
  C2: knowledgeC2,
  C3: knowledgeC3,
  C4: knowledgeC4,
  C5: knowledgeC5,
  C6: knowledgeC6,
  C7: knowledgeC7,
};

export const spirituality = {
  C0: spiritualityC0,
  C1: spiritualityC1,
  C2: spiritualityC2,
  C3: spiritualityC3,
  C4: spiritualityC4,
  C5: spiritualityC5,
  C6: spiritualityC6,
  C7: spiritualityC7,
};

export const compassion = {
  C0: compassionC0,
  C1: compassionC1,
  C2: compassionC2,
  C3: compassionC3,
  C4: compassionC4,
  C5: compassionC5,
  C6: compassionC6,
  C7: compassionC7,
};

export const relativity = {
  C0: relativityC0,
  C1: relativityC1,
  C2: relativityC2,
  C3: relativityC3,
  C4: relativityC4,
  C5: relativityC5,
  C6: relativityC6,
  C7: relativityC7,
};

export const time = {
  C0: timeC0,
  C1: timeC1,
  C2: timeC2,
  C3: timeC3,
  C4: timeC4,
  C5: timeC5,
  C6: timeC6,
  C7: timeC7,
};

export const scapes = [
  { audio: scapeRelativity, name: ['relativity', 'imagination'] },
  { audio: scapeCompassion, name: ['compassion', 'equality'] },
  { audio: scapeFear, name: ['fear'] },
  { audio: scapeExistentialism, name: ['existentialism', 'challenge'] },
  { audio: scapeKnowledge, name: ['knowledge', 'progress'] },
  { audio: scapeHope, name: ['hope', 'love'] },
  { audio: scapeSpirituality, name: ['spirituality', 'life', 'culture'] },
  { audio: scapeTime, name: ['time', 'perspective'] },
];

export const relativityScape = {
  audio: scapeRelativity,
  name: ['relativity', 'imagination'],
};
export const compassionScape = {
  audio: scapeCompassion,
  name: ['compassion', 'equality'],
};
export const fearScape = { audio: scapeFear, name: ['fear'] };
export const existentialismScape = {
  audio: scapeExistentialism,
  name: ['existentialism', 'challenge'],
};
export const knowledgeScape = {
  audio: scapeKnowledge,
  name: ['knowledge', 'progress'],
};
export const hopeScape = { audio: scapeHope, name: ['hope', 'love'] };
export const spiritualityScape = {
  audio: scapeSpirituality,
  name: ['spirituality', 'life', 'culture'],
};
export const timeScape = { audio: scapeTime, name: ['time', 'perspective'] };
