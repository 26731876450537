import { Transition } from '@headlessui/react';

const Nudge = ({
  show,
  line,
  text,
  delay = 'delay-[1000ms]',
}: {
  show: boolean;
  line?: boolean;
  text: string;
  delay?: string;
}) => {
  return (
    <Transition
      show={show}
      appear
      unmount={false}
      className="opacity-0 duration-[2000ms] pointer-events-none select-none transition-opacity"
      enter={delay}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {line && (
        <div className="absolute bottom-32 top-[58%] left-1/2 -translate-x-1/2 border-l"></div>
      )}
      <div className="absolute bottom-16 left-1/2 -translate-x-1/2 text-center">
        {text}
      </div>
    </Transition>
  );
};

export default Nudge;
