import { ReactNode } from 'react';

type IntroButtonProps = {
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
};

const MainButton = ({
  children,
  onClick,
  disabled = false,
  className = '',
}: IntroButtonProps) => {
  return (
    <button
      aria-label={`${children}`}
      disabled={disabled}
      className={`${!disabled ? 'border-b' : ''} leading-10 ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MainButton;
