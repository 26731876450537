import { useTranslation } from 'react-i18next';
import { Node } from '../../types';

const Laureate = ({ node }: { node: Node }) => {
  const { i18n } = useTranslation();

  return (
    <div>
      <div className="font-sans uppercase">{node.author}</div>
      <div className="font-sans normal-case pt-1">
        {i18n.language.includes('sv') ? node.category_sv : node.category_en}{' '}
        {node.year}
      </div>
    </div>
  );
};

export default Laureate;
